import React, { Fragment } from "react";
import CarouselComponent from "./Carousel";
import Counter from "./Count";
import ImageDisplay from "./ImageDisplay";
import FAQ from "./FAQ";
import AppointmentSlider from "./AppointmentSlider";
import EntryGateSlider from "./EntryGateSlider";
import EventSlider from "./EventSlider";

function ParentComponent() {
  return (
    <Fragment>
      <CarouselComponent />
      <Counter />
      <ImageDisplay />

      <AppointmentSlider backgroundColor="#e2f1ff" />
      <EventSlider backgroundColor="#FFF" />
      <EntryGateSlider backgroundColor="#e2f1ff" />
      {/* <FAQ /> */}
      {/* <AboutUs /> */}
      {/* <Contact /> */}
    </Fragment>
  );
}

export default ParentComponent;
