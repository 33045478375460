import { Button, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TextField from "../../components/TextField";
import { Label } from "../../components";
import ConfirmDialog from "./Confirm";

function UserDetails({ formikProps, setUserStep }) {
  const [confirm, setConfirm] = useState(false);

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              // boxShadow: "rgb(45, 151, 131) 0px -2px 8px 0px",
              border: "3px solid rgb(45, 151, 131)",
            }}
          >
            <img
              src={
                Boolean(
                  formikProps.values?.userDetails?.appointment_data?.face_data
                )
                  ? "data:image/png;base64, " +
                    formikProps?.values?.userDetails?.appointment_data
                      ?.face_data
                  : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
              }
              width={"100%"}
              height={"100%"}
              style={{
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            ></img>
          </Grid>
        </Grid>

        <Grid item container display={"flex"} justifyContent={"center"}>
          <Grid
            item
            xs={5}
            sx={{
              boxShadow: "0px 1px 8px 0px rgb(45, 151, 131)",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      color: "#7e7e7e",
                    }}
                  >
                    Name
                  </td>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {formikProps.values?.userDetails?.appointment_data
                      ?.first_name +
                      " " +
                      formikProps.values?.userDetails?.appointment_data
                        ?.last_name}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      color: "#7e7e7e",
                    }}
                  >
                    Phone Number
                  </td>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {
                      formikProps.values?.userDetails?.appointment_data
                        ?.mobile_number
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      color: "#7e7e7e",
                    }}
                  >
                    Email ID
                  </td>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {formikProps.values?.userDetails?.appointment_data?.email}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      color: "#7e7e7e",
                    }}
                  >
                    Booking Id
                  </td>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {
                      formikProps.values?.userDetails?.appointment_data
                        ?.booking_id
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                      color: "#7e7e7e",
                    }}
                  >
                    Booking Verification Status
                  </td>
                  <td
                    style={{
                      // border: " 2px solid black",
                      padding: "5px",
                      fontWeight: 600,
                    }}
                  >
                    {formikProps.values?.userDetails?.booking_exists ? (
                      <CheckCircleOutlineRoundedIcon
                        sx={{
                          fontSize: "30px",
                          lineHeight: "21px",
                          float: "center",
                          textTransform: "uppercase",
                          color: "green",
                        }}
                      />
                    ) : (
                      <CancelRoundedIcon
                        sx={{
                          fontSize: "30px",
                          lineHeight: "21px",
                          float: "center",
                          textTransform: "uppercase",
                          color: "red",
                        }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

        <Grid
          item
          container
          display={"flex"}
          justifyContent={"center"}
          // spacing={2}
          mt={7.5}
        >
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setUserStep("idDetails");
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        confirm={confirm}
        setConfirm={setConfirm}
        formikProps={formikProps}
      />
    </Fragment>
  );
}

export default UserDetails;
