import React from "react";
import { TextField } from "@mui/material";

const OTPInput = ({
  value,
  onChange,
  onKeyDown,
  index,
  inputRefs,
  otpLength,
}) => {
  return (
    <TextField
      inputRef={(el) => (inputRefs.current[index] = el)}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyPress={(e) => {
        if (!/^[0-9]$/.test(e.key)) {
          e.preventDefault();
        }
      }}
      style={{
        width: "50px",
        background: "#FFFFFF",
        borderRadius: "5px",
      }}
      inputProps={{
        type: "tel",
        maxLength: 1,
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      }}
    />
  );
};

export default OTPInput;
