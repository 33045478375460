import React from "react";

import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

//import { setDLData, setAadharData } from "../../redux/slices/documentSlice";
import VideoPreview from "./Preview";

export default function PreviewVideo() {
  const userData = useLocation();

  return (
    <React.Fragment>
      <Grid container spacing={2} p="20px">
        <Grid
          item
          container
          justifyContent={"end"}
          xs={6}
          sm={4}
          md={3}
          lg={5}
          xl={5}
        >
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "26px",
              color: "black",
              lineHeight: "30px",
            }}
          >
            Image Preview
          </Typography>
        </Grid>
      </Grid>
      <VideoPreview userData={userData} />
    </React.Fragment>
  );
}
