import React from "react";
import { Typography } from "@mui/material";

const SubheaderSection = ({ textAlign, title, content, variant = "h2" }) => (
  <>
    <Typography
      variant={variant}
      sx={{
        marginBottom: "5px",
        textAlign: textAlign || "left",
        color: "#1E1E1E",
        fontFamily: "'Poppins'",
      }}
    >
      {title || ""}
    </Typography>

    <Typography
      variant="body1"
      sx={{
        marginBottom: "26px",
        textAlign: textAlign || "left",
        color: "gray",
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: content || "" }} />
    </Typography>
  </>
);

export default SubheaderSection;
