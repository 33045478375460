import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import GlobalStyle from "../components/GlobalStyle";
import MainContent from "./application layout components/MainContent";
import Navbar from "./application layout components/Navbar";
import WebsiteLoader from "./application layout components/WebsiteLoader";
import Footer from "./application layout components/Footer";

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  background: rgb(255, 255, 255);
`;

const MainContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export default function ApplicationLayout() {
  const [mainContentLoaded, setMainContentLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMainContentLoaded(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />
      {!mainContentLoaded ? <WebsiteLoader /> : null}
      <ContentWrapper>
        <MainContentWrapper>
          <Navbar />
          {mainContentLoaded ? <MainContent /> : null}
          <Footer />
        </MainContentWrapper>
      </ContentWrapper>
    </Fragment>
  );
}
