import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAxios, useAlert } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { OuterBox, SubheaderSection } from "./../../components";
import OTPVerification from "./OTPVerification";
import MobileNumberInput from "./MobileNumberInput";
import pages from "../../constants/pages";

const MobileVerification = ({
  selectedOption,
  numberOfInputs = 4,
  setProcced,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alert } = useAlert();
  const axios = useAxios();
  const [mobileNumberOtpGenrated, setMobileNumberOtpGenrated] = useState(false);
  const [otp, setOTP] = useState(new Array(numberOfInputs).fill(""));
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(
    sessionStorage.getItem("mobile") ? sessionStorage.getItem("mobile") : ""
  );

  const handleGoBack = () => {
    setProcced(false);
  };

  const handleOtpGoBack = () => {
    setMobileNumberOtpGenrated(false);
  };

  const [userConsent, setUserConsent] = useState(0);

  const handleUserConsentChange = (event) => {
    const isChecked = event.target.checked;

    const consentValue = isChecked ? "1" : "0";
    setUserConsent(consentValue);
  };

  const handleOTPSubmit = () => {
    const otpString = otp.join("");
    const numericOtpString = otpString.replace(/\D/g, "");
    const numericOtp = parseInt(numericOtpString, 10);

    if (isNaN(numericOtp) || numericOtpString.length !== 4) {
      dispatch(
        alert({
          type: "error",
          message: "Invalid OTP. Please enter a 4-digit numeric code.",
        })
      );
      return;
    }

    setLoading(true);

    axios({
      url: `/booking/appointment/verify-otp?mobile_no=91${mobileNumber}&otp=${numericOtp}&on_whatsapp=${userConsent}`,
      method: "POST",
    })
      .then((response) => {
        if (response.status) {
          setLoading(false);
          if (response.data.id) {
            sessionStorage.setItem("is_verified", response.data.is_verified);
            sessionStorage.setItem("visitorId", response.data.id);

            if (selectedOption === "Book Appointment") {
              navigate(pages.AppointmentBooking.route);
            } else if (selectedOption === "Event Booking") {
              navigate(
                pages.EventBooking.route +
                  "/" +
                  mobileNumber +
                  "/" +
                  response.data?.id,
                {}
              );
            }

            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message.displayMessage,
              })
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
      });
  };

  const handleResendOTP = () => {
    setLoading(true);
    setOTP(new Array(numberOfInputs).fill(""));
    axios({
      url: `/booking/appointment/resend-otp?mobile_no=91${mobileNumber}`,
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          dispatch(
            alert({
              type: "success",
              message: response.message.displayMessage,
            })
          );

          setMobileNumberOtpGenrated(true);
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetOTP = () => {
    setOTP(new Array(numberOfInputs).fill(""));
    setLoading(true);
    axios({
      url: `/booking/appointment/send-otp?mobile_no=91${mobileNumber}`,
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          dispatch(
            alert({
              type: "success",
              message: response.message.displayMessage,
            })
          );

          setMobileNumberOtpGenrated(true);
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (mobileNumberOtpGenrated) {
    return (
      <OuterBox>
        <SubheaderSection
          textAlign="left"
          title="Mobile Verification"
          content={`Please enter the OTP received on your mobile number <b>${mobileNumber}</b>`}
        />

        <OTPVerification
          handleOtpGoBack={handleOtpGoBack}
          loading={loading}
          otpLength={numberOfInputs}
          onSubmit={handleOTPSubmit}
          onResend={handleResendOTP}
          setOTP={setOTP}
          otp={otp}
        />
      </OuterBox>
    );
  }

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title=" Mobile Verification"
        content="Enter Your 10 Digit Mobile Number and click 'Get OTP' to verify"
      />

      <MobileNumberInput
        handleGoBack={handleGoBack}
        handleUserConsentChange={handleUserConsentChange}
        loading={loading}
        onSubmit={handleGetOTP}
        setMobileNumber={setMobileNumber}
        mobileNumber={mobileNumber}
      />
    </OuterBox>
  );
};

export default MobileVerification;
