import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";

import uploadToS3 from "../../utils/uploadToS3";
import FileUpload from "../../components/FileUpload";
import { useAxios } from "../../hooks";

function ImgCapture({ bioFormikProps, videoRef, onSubmit }) {
  const axios = useAxios({ baseURL: "mox_api_2" });
  // const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const uploadedFiles = useRef([]);

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 620,
          facingMode: "environment",
        },
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      if (error?.name === "NotReadableError") {
        console.log(
          "NotReadableError: The camera might be in use or not available"
        );
        alert(
          "Unable to access the camera. Please ensure it's not in use by another application and refresh the page."
        );
        window.location.reload();
      } else if (error.name === "OverconstrainedError") {
        console.log(
          "OverconstrainedError: The constraints don't match any installed camera"
        );
        alert("No suitable camera found. Please check your camera settings.");
      } else {
        alert(`Error accessing camera: ${error.message}`);
      }
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");

      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      const captureSize = 350;
      const sourceX = (videoWidth - captureSize) / 2;
      const sourceY = (videoHeight - captureSize) / 2;

      context.drawImage(
        video,
        sourceX,
        sourceY,
        captureSize,
        captureSize,
        0,
        0,
        canvas.width,
        canvas.height
      );
      const imageDataURL = canvas.toDataURL("image/jpeg");

      bioFormikProps.setValues((prevValues) => ({
        ...prevValues,
        ImgData: imageDataURL.replace(/^data:image\/[a-z]+;base64,/, ""),
      }));

      stopCamera();

      if (onSubmit) {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    startCamera();

    return () => {
      stopCamera();
    };
  }, []);

  useEffect(() => {
    const handleOrientation = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (video && canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }
    };

    window.addEventListener("resize", handleOrientation);
    window.addEventListener("orientationchange", handleOrientation);

    return () => {
      window.removeEventListener("resize", handleOrientation);
      window.removeEventListener("orientationchange", handleOrientation);
    };
  }, []);
  return (
    <Grid item container display={"flex"} justifyContent={"center"}>
      <Grid item container justifyContent={"center"}>
        <Typography textAlign={"center"} fontWeight={"bold"}>
          Click Picture
        </Typography>
      </Grid>
      <Grid
        item
        className="videoBox2"
        position={"relative"}
        // left={"17%"}
        width={"30%"}
      >
        <svg id="svg-overlay" viewBox="0 0 100 130">
          <path
            d="M50 5
                 C 25 5, 5 30, 5 65
                 C 5 90, 15 110, 35 120
                 C 40 123, 45 125, 50 125
                 C 55 125, 60 123, 65 120
                 C 85 110, 95 90, 95 65
                 C 95 30, 75 5, 50 5"
            fill="none"
            stroke="#ff438e"
            stroke-width="1.5"
          />
        </svg>
        <video
          id="video"
          style={{ width: "100%", height: "100%", objectFit: "initial" }} // Make the video fill its container
          ref={videoRef}
          muted
          autoPlay
          playsInline
        ></video>
      </Grid>
      <Grid item container justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          id="click-photo"
          loading={bioFormikProps.isSubmitting}
          // disabled={!bioFormikProps.values.gateNo}
          type="button"
          onClick={takePhoto}
        >
          Click Picture
        </LoadingButton>
      </Grid>
      <canvas
        id="canvas"
        width="480"
        height="600"
        ref={canvasRef}
        style={{ display: "none" }}
      ></canvas>
    </Grid>
  );
}

export default ImgCapture;
