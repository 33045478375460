import React from "react";
import {
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/AddSharp";
import ExpandLessIcon from "@mui/icons-material/Remove";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  FAQWrap: {
    padding: "20px 0px",
    backgroundColor: "#ffffff",
    fontSize: "20px",
  },
  FAQItem: {
    "&:hover": {
      color: "#034fc8",
    },
    "&.Mui-expanded": {
      color: "#000",
    },
  },
  FAQQuestion: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    padding: "12px 20px",
    transition: "all 0.5s ease 0s",
    margin: "0px",
  },
  plusMinusIcon: {
    fontWeight: "900",
    width: "35px",
    height: "35px",
    fontSize: "32px",
    color: "#107de2",
  },
  FAQHeading: {
    margin: "0",
    paddingBottom: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 300,
    fontFamily: '"Montserrat", sans-serif',
  },
  AccordionSummary: {
    // Remove the border and margin
    boxShadow: "none",
  },
  FAQAnswer: {
    marginLeft: "20px",
    marginRight: "20px",
    fontSize: "17px", // Reduce font size for mobile
    letterSpacing: "0.5px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 300,
    lineHeight: 1.5,
  },
}));

const FAQ = () => {
  const faqItems = [
    {
      question:
        "Q.1 : What data needs to be asked from the user while  booking the appointment?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla  facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero, egestas tempus eros scelerisque eu.",
    },
    {
      question: "Q.2 : How many attempts are allowed to verify Identity? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        "Q.3 : In how much time should a verified user need to reverify? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        "  Q.4 : What would be the fallback Mechanism for Identity verification failure? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question: "Q.5 : Which Identity Proofs should be allowed?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        "Q.6 : How to handle multiple people coming to meet a single person or have a group meeting? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        "Q.7 : What questions need to be asked for creating an event from admins? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        " Q.8 : How are we planning to handle the approval journey  of the events? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        " Q.9 : How early can we allow a visitor to get in before meeting time? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        "Q.10 : Are we planning to integrate this with the attendance management system?  ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        " Q.11 : Where do need to send OTP for mobile numberverification of the visitor?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },

    {
      question:
        "Q.12 : What would be the duration of single meeting? And can officers create different time slots with different  durations?  ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
    {
      question:
        " Q.13 : What would be the duration of single meeting? And can officers create different time slots with different durations?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacinia lacinia mauris, at ultricies leo ornare  nec. Vivamus eu est vel felis dignissim tempor. Nulla facilisi. Duis molestie tortor ac tempor volutpat.  Phasellus nec mi aliquet, sollicitudin neque eget,consectetur libero. Vestibulum sollicitudin sapien libero,egestas tempus eros scelerisque eu. ",
    },
  ];

  const [expanded, setExpanded] = React.useState(false);

  const toggleAccordion = (index) => {
    if (expanded === index) {
      setExpanded(false);
    } else {
      setExpanded(index);
    }
  };

  const classes = useStyles();

  return (
    <Container sx={{ padding: "50px 0px 30px 0px" }}>
      <Typography variant="h1" className={classes.FAQHeading}>
        FAQs
      </Typography>
      {faqItems.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={() => toggleAccordion(index)}
          className={classes.FAQItem}
        >
          <AccordionSummary
            expandIcon={
              expanded === index ? (
                <ExpandLessIcon className={classes.plusMinusIcon} />
              ) : (
                <ExpandMoreIcon className={classes.plusMinusIcon} />
              )
            }
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
            className={classes.AccordionSummary}
          >
            <Typography className={classes.FAQQuestion}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.FAQAnswer}>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQ;
