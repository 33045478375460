import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";

const SelectField = ({
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ marginBottom: "16px" }}
        error={error && Boolean(error)}
      >
        <InputLabel
          id={`demo-simple-select-${name}`}
          sx={{ paddingRight: "20px" }}
        >
          {label}
          <span style={{ color: "red", float: "right", paddingLeft: "5px" }}>
            *
          </span>
        </InputLabel>
        <Select
          label={label}
          labelId={`demo-simple-select-${name}`}
          id={`demo-simple-${name}`}
          displayEmpty
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={options.length === 0}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ justifyContent: "space-between" }}
            >
              <span>{option.name} </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SelectField;
