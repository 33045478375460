import React, { Fragment, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { useAxios } from "../../hooks/";
import pages from "../../constants/pages";
import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
  ButtonContainer,
} from "./../../components";

export default function VideoPreview({ userData }) {
  const axios = useAxios({ baseURL: "mox_api_2" });

  const axiosDigipass = useAxios({
    baseURL: "digipass",
    disableAuthToken: true,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { visitor_id, id, ref_id, booking_type, bookingId, eventId } =
    location.state || {};

  const [loading, setLoading] = useState(false);
  const [livelinessError, setLivelinessError] = useState({});

  function captureAgain() {
    navigate(pages.checkLiveliness.route, {
      state: {
        visitor_id: visitor_id,
        id: id,
        ref_id: ref_id,
        booking_type: booking_type,
      },
    });
  }

  const checkingLiveliness = async () => {
    setLoading(true);

    const fileurl = userData.state.blob;
    const aadharData = JSON.parse(localStorage.getItem("userPayload"));

    axiosDigipass({
      url: `/verify/booking`,
      method: "POST",
      disableRedirect: true,
      data: {
        name: " ",
        face_data: fileurl.replace(/^data:image\/[a-z]+;base64,/, ""),
        booking_id: bookingId || sessionStorage.getItem("bookingID"),
        event_id: eventId || sessionStorage.getItem("eventID"),
      },
    })
      .then((response) => {
        if (response.status) {
          const destination = `${pages.AadharverificaionSuccess?.route}?qrcodeurl=${response.data.qr_link}`;
          navigate(destination, {
            state: {
              visitor_id: visitor_id,
              id: id,
              ref_id: ref_id,
              booking_type: booking_type,
              bookingId: bookingId,
              eventId: eventId,
            },
          });
        } else {
          setLoading(false);

          setLivelinessError(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.error("Error in Aws upload:", error);
      });
  };

  function proceedToNext(fileurl) {
    setLoading(true);
    const userPayloadString = localStorage.getItem("userPayload");
    const userPayload = JSON.parse(userPayloadString);
    axios({
      url: "/booking/appointment/verification",
      method: "PATCH",
      data: {
        livliness_check_status: 1,
        livliness_video_url: fileurl,
        ...userPayload,
        visitor_id: sessionStorage.getItem("visitorId"),
        id: sessionStorage.getItem("id"),
      },
    })
      .then((response) => {
        if (response.status) {
          // console.log(response.data);

          const destination = `${pages.Success?.route}?ref-id=${ref_id}&booking-type=event`;
          navigate(destination, {
            state: {
              visitor_id: visitor_id,
              id: id,
              ref_id: ref_id,
              booking_type: booking_type,
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error update verification detils:", error);
      });
  }

  return (
    <Fragment>
      {livelinessError?.name_match !== undefined &&
      !livelinessError?.name_match &&
      !livelinessError?.face_match ? (
        <OuterBox>
          <SubheaderSection textAlign="center" title="Verification Failed" />

          <form>
            <Box>
              <Grid
                item
                sx={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CancelRoundedIcon
                  sx={{ color: "red", fontSize: "60px", marginBottom: "15px" }}
                />
                <Typography variant="h6" sx={{ color: "red" }}>
                  {livelinessError?.booking_already_verified
                    ? "Booking already verified"
                    : "Details Not Match"}
                  <br />
                </Typography>
              </Grid>
            </Box>

            <ButtonContainer>
              <LoadingButton
                onClick={() =>
                  navigate(
                    pages.Verification.route +
                      `?visitor_id=${visitor_id}&booking_type=no&ref_id=DIGI&id=0`
                  )
                }
              >
                Try Again
              </LoadingButton>
            </ButtonContainer>
          </form>
        </OuterBox>
      ) : (
        <OuterBox>
          <SubheaderSection
            textAlign="left"
            title="Face Verification"
            content=" Before proceeding, check the image preview"
          />

          <form>
            <Box>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <img
                  className="userImage"
                  src={userData.state.blob}
                  alt="Preview"
                />
              </Grid>

              {livelinessError?.face_match && (
                <Grid
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "red", mb: 2 }}>
                    Verification Failed
                  </Typography>
                  <Typography variant="body1">
                    Face Don't Match
                    <br />
                  </Typography>
                </Grid>
              )}

              {loading && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: "16px" }}
                >
                  It may take a few seconds
                </Typography>
              )}
            </Box>

            <ButtonContainer>
              {!Boolean(livelinessError?.name_match) && (
                <LoadingButton loading={loading} onClick={checkingLiveliness}>
                  Proceed Next
                </LoadingButton>
              )}

              <LoadingButton
                onClick={captureAgain}
                backgroundColor="gray"
                disabled={loading}
              >
                Capture Again
              </LoadingButton>
            </ButtonContainer>
          </form>
        </OuterBox>
      )}
    </Fragment>
  );
}
