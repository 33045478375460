import { Label, OuterBox, SubheaderSection, TextField } from "../../components";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImgCapture from "./ImgCapture";
import { QrReader } from "react-qr-reader";
import { useAxios } from "../../hooks";

import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import UserDetails from "./UserDetails";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { setDisplayName } from "../../redux/slices/memberSlice";

const ManualVerification = () => {
  const axios = useAxios({
    baseURL: "mox_api_2",
  });

  const dispatch = useDispatch();

  const { authToken, displayName, eventID } = useSelector(
    (state) => state.memberDetails
  );

  const [guardDetails, setGuardDetails] = useState({
    name: displayName || "",
    gateNo: 0,
    QRCode: "",
    ImgData: "",
    reason: "",
    userDetails: undefined,
    isAllowed: true,
    docType: "",
    docNo: "",
  });

  const [loading, setLoading] = useState(false);

  const [userStep, setUserStep] = useState("idDetails");

  const location = useLocation();

  const videoRef = useRef(null);

  const [gates, setGates] = useState([]);

  const [agentName, setAgentName] = useState(displayName || "");

  const [selectedGate, setSelectedGate] = useState(
    Number(sessionStorage.getItem("gateMate")) || 0
  );

  const [bookingExistsStatus, setBookingExistsStatus] = useState(undefined);
  const [bookingAlreadyVerifiedStatus, setBookingAlreadyVerifiedStatus] =
    useState(undefined);
  const [QRLink, setQRLink] = useState(undefined);

  const [appointmentStatus, setAppointmentStatus] = useState(undefined);

  useEffect(() => {
    if (Boolean(displayName)) {
      setUserStep("idDetails");
    }

    axios({ url: "/visitor/dome-list", disableRedirect: true }).then(
      (response) => {
        if (response.status) {
          setGates(response.data);
        }
      }
    );
  }, []);

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [location]);

  function fetchUserDetails(formikProps, id) {
    setLoading(true);
    axios({
      url: "/booking/visitor-details/" + id,
      customAuthToken: authToken,

      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        if (
          response.data?.booking_already_verified &&
          response.data?.booking_exists
        ) {
          setBookingExistsStatus(response.data?.booking_exists);
          setBookingAlreadyVerifiedStatus(
            response.data?.booking_already_verified
          );
        }

        if (
          !response.data?.booking_already_verified &&
          !response.data?.booking_exists
        ) {
          setBookingExistsStatus(response.data?.booking_exists);
        }

        if (
          !response.data?.booking_already_verified &&
          response.data?.booking_exists
        ) {
          setUserStep("userDetails");
          formikProps.setValues((prevValues) => ({
            ...prevValues,
            QRCode: id,
            userDetails: response.data,
          }));
        }

        stopCamera();
      }
      setLoading(false);
    });
  }

  return (
    <OuterBox>
      {bookingAlreadyVerifiedStatus === undefined &&
        bookingExistsStatus === undefined && (
          <Formik
            initialValues={guardDetails}
            validationSchema={validationSchema}
            onSubmit={(formData, { setSubmitting }) => {
              setSubmitting(true);

              let url = "/booking/update-visitor-details";

              axios({
                url: url,
                method: "POST",
                data: {
                  booking_id: formData?.bookingID,
                  face_data: formData?.ImgData,
                  agent_name: agentName,
                  is_manual: true,
                  event_id: eventID || sessionStorage.getItem("eventID"),
                },
                customAuthToken: authToken,
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setBookingExistsStatus(response.data?.booking_exists);

                  setBookingAlreadyVerifiedStatus(
                    response.data?.booking_already_verified
                  );
                  setQRLink(response.data.qr_link);
                }
                setSubmitting(false);
                stopCamera();
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  {agentName && userStep !== "" && (
                    <Grid container justifyContent={"end"}>
                      <Typography alignContent={"center"}>
                        Welcome
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {agentName}
                        </span>{" "}
                      </Typography>

                      <Tooltip title="Edit Name">
                        <EditRoundedIcon
                          sx={{
                            fontSize: "30px",
                            borderRadius: "15px",
                            padding: "5px",
                            "&:hover": { background: "rgb(108 108 108 / 87%)" },
                          }}
                          onClick={() => {
                            formikProps.setValues(() => ({
                              name: "",
                              gateNo: 0,
                              QRCode: "",
                              ImgData: "",
                              userDetails: undefined,
                              isAllowed: false,
                            }));

                            const stopCamera = () => {
                              if (
                                videoRef.current &&
                                videoRef.current?.srcObject
                              ) {
                                const tracks =
                                  videoRef.current.srcObject?.getTracks();
                                tracks.forEach((track) => track.stop());
                                videoRef.current.srcObject = null;
                              }
                            };

                            stopCamera();
                            setSelectedGate(0);
                            setUserStep("");
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )}

                  {userStep === "" && (
                    <Grid container>
                      <Grid item xs={4} alignSelf={"center"}>
                        <Label
                          formikProps={formikProps}
                          title={"Enter Your Name"}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField name="name" formikProps={formikProps} />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          variant="contained"
                          sx={{ height: "100%" }}
                          disabled={!formikProps.dirty}
                          onClick={() => {
                            setAgentName(formikProps.values.name);
                            dispatch(setDisplayName(formikProps.values.name));
                            setUserStep("idDetails");
                          }}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {userStep === "QRcode" &&
                    bookingExistsStatus === undefined && (
                      <Grid container>
                        {Boolean(
                          !formikProps.values.userDetails &&
                            !appointmentStatus &&
                            !loading
                        ) && (
                          <Fragment>
                            <Grid item container justifyContent={"center"}>
                              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                Scan QR Code{" "}
                              </Typography>
                            </Grid>
                            <Grid item container justifyContent={"center"}>
                              <Grid item sx={{ width: "50vh" }}>
                                <QrReader
                                  style={{ width: "100%" }}
                                  onResult={(result, error) => {
                                    if (!!result) {
                                      let [eventID, bookingID] =
                                        result?.text.split("_");

                                      fetchUserDetails(formikProps, bookingID);
                                    }

                                    if (!!error) {
                                      // console.info(error);
                                    }
                                  }}
                                  constraints={{
                                    facingMode: "environment",
                                  }}
                                  scanDelay={500}
                                />
                              </Grid>
                            </Grid>
                          </Fragment>
                        )}

                        {loading && (
                          <Fragment>
                            <Grid
                              container
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              m={15}
                            >
                              <CircularProgress sx={{ color: "green" }} />
                              <Typography
                                variant="h6"
                                sx={{ mt: "30px" }}
                                fontWeight={"600"}
                              >
                                Wait Getting User Details
                              </Typography>
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    )}

                  {userStep === "idDetails" && (
                    <Fragment>
                      {/* <Grid item container spacing={3} mt={3}>
                        <Grid item xs={12} display={"flex"}>
                          <Label
                            formikProps={formikProps}
                            title={"Select ID Card"}
                          />

                          <TextField
                            name="docType"
                            formikProps={formikProps}
                            select
                          >
                            <MenuItem value={""}>Select ID</MenuItem>
                            <MenuItem value={"aadhar"}>Aadhaar Card</MenuItem>
                            <MenuItem value={"pan"}>Pan Card</MenuItem>
                            <MenuItem value={"voter"}>Voter ID</MenuItem>
                            <MenuItem value={"dl"}>Driving Licence</MenuItem>
                            <MenuItem value={"passport"}>Passport</MenuItem>
                          </TextField>
                          <Typography color={"red"}>
                            {formikProps.errors?.id}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} display={"flex"}>
                          <Label
                            formikProps={formikProps}
                            title={"Enter ID No"}
                          />

                          <Grid item container justifyContent={"end"}>
                            <TextField name="docNo" formikProps={formikProps} />

                            <Typography color={"red"}>
                              {formikProps.errors?.id_no}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"end"}
                        >
                          <Button
                            variant="contained"
                            sx={{ height: "100%" }}
                            disabled={!formikProps.isValid}
                            onClick={() => setUserStep("takeImage")}
                          >
                            Take User Picture <ArrowForwardIcon />
                          </Button>
                        </Grid>
                      </Grid> */}

                      <Grid item container spacing={3} mt={3}>
                        <Grid item xs={12} display={"flex"}>
                          <Label
                            formikProps={formikProps}
                            title={"Enter Booking ID"}
                          />

                          <Grid item container justifyContent={"end"}>
                            <TextField
                              name="bookingID"
                              formikProps={formikProps}
                            />

                            <Typography color={"red"}>
                              {formikProps.errors?.bookingID}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"end"}
                        >
                          <Button
                            variant="contained"
                            sx={{ height: "100%" }}
                            disabled={
                              !formikProps.isValid || !formikProps.dirty
                            }
                            onClick={() => setUserStep("takeImage")}
                          >
                            Take User Picture <ArrowForwardIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}

                  {userStep === "takeImage" && (
                    <ImgCapture
                      videoRef={videoRef}
                      bioFormikProps={formikProps}
                      onSubmit={formikProps.handleSubmit}
                    />
                  )}

                  {Boolean(formikProps.values.userDetails) &&
                    userStep === "userDetails" && (
                      <UserDetails
                        formikProps={formikProps}
                        setUserStep={setUserStep}
                      />
                    )}
                </Fragment>
              );
            }}
          </Formik>
        )}

      {bookingAlreadyVerifiedStatus === false && bookingExistsStatus && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <CheckCircleOutlineRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
              marginBottom: "30px",
            }}
          />
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
            }}
          >
            Verification Done
          </Typography>

          <Button
            variant="outlined"
            sx={{ marginTop: "30px" }}
            onClick={() => {
              setUserStep("idDetails");
              setBookingExistsStatus(undefined);
              setBookingAlreadyVerifiedStatus(undefined);
            }}
          >
            Take Next Entry
          </Button>
        </Grid>
      )}

      {bookingExistsStatus !== undefined &&
        bookingExistsStatus === false &&
        bookingAlreadyVerifiedStatus !== false && (
          <Grid
            container
            display={"flex"}
            sx={{ flexDirection: "column" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CancelRoundedIcon
              sx={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
              }}
            >
              Booking not found
            </Typography>
            <Button
              variant="outlined"
              sx={{ marginTop: "30px", color: "red", border: "1px solid red" }}
              onClick={() => {
                setUserStep("idDetails");
                setBookingExistsStatus(undefined);
                setBookingAlreadyVerifiedStatus(undefined);
              }}
            >
              Try Again
            </Button>
          </Grid>
        )}

      {bookingAlreadyVerifiedStatus !== undefined &&
        bookingAlreadyVerifiedStatus === true &&
        bookingExistsStatus === true && (
          <Grid
            container
            display={"flex"}
            sx={{ flexDirection: "column" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CheckCircleOutlineRoundedIcon
              sx={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "green",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "green",
              }}
            >
              Booking Already Verified
            </Typography>
            <Button
              variant="outlined"
              sx={{
                marginTop: "30px",
              }}
              onClick={() => {
                setUserStep("idDetails");
                setBookingExistsStatus(undefined);
                setBookingAlreadyVerifiedStatus(undefined);
              }}
            >
              Try Again
            </Button>
          </Grid>
        )}
    </OuterBox>
  );
};

const validationSchema = Yup.object().shape({
  // docType: Yup.string().required("Document Number is required"),
  // docNo: Yup.string().required("Document Number is required"),

  bookingID: Yup.string().required("Booking ID is required"),
});

export default ManualVerification;
