import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "./assets/img/getyourpass.png"; // Import the images
import { motion } from "framer-motion";
const useStyles = makeStyles((theme) => ({
  getYourPassWrap: {
    margin: 0,
    padding: "88px 0",
    background: `url(${Image}) top center no-repeat`,
    backgroundSize: "cover",
    borderTop: "1px solid #d6d6d6",
    borderBottom: "1px solid #000000",
    textAlign: "center",
    color: "#ffffff",
  },
  btn: {
    borderRadius: "30px",
    padding: "0 40px",
    fontSize: "24px",
    backgroundColor: "#107de2",
    fontWeight: 300,
    width: "auto",
    color: "#FFF",
    textAlign: "justify",
    float: "inline-start",
    border: "1px solid #000",
    "&:hover": {
      backgroundColor: "#107de2",
      borderRadius: "30px",
    },
  },
  heading: {
    color: "#1e1e1e",
    textAlign: "justify",
    fontSize: "64px",
    margin: 0,
    fontFamily: "Montserrat, sans-serif",
    padding: "0px 0px 30px 0px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "53px",
    },
  },
}));

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
};

function ImageDisplay() {
  const classes = useStyles();
  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visible">
      <div className={classes.getYourPassWrap}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom className={classes.heading}>
            Get Your <br /> Digi Pass
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            href="/appointment"
          >
            Appointment Now
          </Button>
        </Container>
      </div>
    </motion.div>
  );
}

export default ImageDisplay;
