import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
  MandatoryNote,
  CaptchaDisplay,
  ButtonContainer,
} from "./../../../components";

import pages from "./../../../constants/pages";
import convertNormalDate from "./../../../utils/convertNormalDate";
import { useAlert, useAxiosV2, useAxios } from "./../../../hooks/";

const MyComponent = ({
  token,
  bookingType,
  visitorId,
  setVerificationFailedCount,
  setVerification,
}) => {
  const axios = useAxios();
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });

  const { alert } = useAlert();

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [CaptchaError, setCaptchaError] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");
  const ref_id = queryParams.get("ref_id");
  const booking_type = queryParams.get("booking_type");

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCaptcha = "";
    for (let i = 0; i < 6; i++) {
      randomCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(randomCaptcha);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleGoBack = () => {
    setVerification(false);
  };

  function handleVerifyDrivingLicence(values) {
    if (values.captchaInput !== captcha) {
      setCaptchaError(true);
      return;
    }

    setLoading(true);
    axiosAadhar({
      url: "/verify/dl/",
      method: "POST",
      authToken: token,
      data: {
        dl_no: values.dl_number,
        dob: convertNormalDate(values.dob),
        consent: "Y",
        consent_text: "Hello This is Metawing ",
      },
    }).then((response) => {
      if (response.response_code === "101") {
        const name = response.result.name;
        const nameParts = name.split(" ");
        const first_name = nameParts[0];
        let last_name = nameParts.slice(1).join(" ");
        if (last_name.trim() === "") {
          last_name = first_name;
        }
        // console.log("nameParts", nameParts);
        var payload = {
          doc_type: "dl",
          dlNo: response.result.dlNumber,

          dob: response.result.dob,
          address:
            response.result?.address[0]?.completeAddress ||
            "" + response.result?.address[0]?.state ||
            "" + response.result?.address[0]?.pin,
          visitor_id: visitorId,
          booking_type: "event",
          first_name: first_name,
          last_name: last_name,

          doc_face_image: response.result.img,
        };
        // updateVerificationDetails(payload);
        const payloadString = JSON.stringify(payload);
        localStorage.setItem("userPayload", payloadString);
        setLoading(false);

        dispatch(
          alert({
            type: "success",
            message: "DL Verified successfully.",
          })
        );

        navigate(pages.checkLiveliness.route, {
          state: {
            visitor_id: visitorId,
            id: id,
            ref_id: ref_id,
            booking_type: booking_type,
          },
        });
      } else {
        setLoading(false);
        setVerificationFailedCount((prevCount) => prevCount + 1);
        dispatch(
          alert({
            type: "error",
            message: "Details not found, please enter the correct details.",
          })
        );
      }
    });
  }

  const onSubmit = async (values) => {
    try {
      handleVerifyDrivingLicence(values);
    } catch (error) {
      dispatch(
        alert({
          type: "error",
          message: error,
        })
      );
    }
  };

  const updateVerificationDetails = (data) => {
    axios({
      url: `/booking/appointment/verification`,
      method: "PATCH",
      data: { ...data },
    })
      .then((response) => {
        data.is_verified = 1;
        const payloadString = JSON.stringify(data);
        localStorage.setItem("userPayload", payloadString);

        if (response.status) {
          setLoading(false);
          console.log(response.message.displayMessage);
          dispatch(
            alert({
              type: "success",
              message: "DL Verified successfully.",
            })
          );

          navigate(pages.checkLiveliness.route, {
            state: {
              visitor_id: visitorId,
              id: id,
              ref_id: ref_id,
              booking_type: booking_type,
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error update detils:", error);
      });
  };

  const validationSchema = Yup.object().shape({
    dl_number: Yup.string().required("Dl Number is required"),
    dob: Yup.string().required("Dob is required"),
    captchaInput: Yup.string()
      .required("Captcha is required")
      .test("captcha-match", "Captcha does not match", function (value) {
        return value === captcha;
      }),
  });

  const formik = useFormik({
    initialValues: {
      dl_number: "",
      dob: "",
      captchaInput: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title="Driving Licence"
        content="Enter your driving license details to continue"
      />

      <form>
        <TextField
          fullWidth
          sx={{ marginBottom: "16px" }}
          variant="outlined"
          label={
            <Fragment>
              Enter Driving Licence Number
              <span
                style={{
                  color: "red",
                  float: "right",
                  paddingLeft: "5px",
                }}
              >
                *
              </span>
            </Fragment>
          }
          placeholder="Enter Driving Licence Number"
          name="dl_number"
          value={formik.values.dl_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.dl_number && Boolean(formik.errors.dl_number)}
        />

        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth variant="outlined">
              <DatePicker
                label={
                  <>
                    Select Date Of Birth
                    <span
                      style={{
                        color: "red",
                        float: "right",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span>
                  </>
                }
                autoComplete="off"
                placeholder="Select Date Of Birth"
                value={formik.values.dob}
                onBlur={formik.handleBlur}
                error={formik.touched.date && Boolean(formik.errors.dob)}
                onChange={(newValue) => formik.setFieldValue("dob", newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"dob"}
                    onBlur={formik.handleBlur}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    value={formik.values.dob}
                  />
                )}
                inputFormat="dd/MM/yyyy" // Set the desired date format here
                maxDate={new Date()}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>

        <TextField
          label={
            <Fragment>
              Enter Captcha
              <span
                style={{
                  color: "red",
                  float: "right",
                  paddingLeft: "5px",
                }}
              >
                *
              </span>
            </Fragment>
          }
          fullWidth
          margin="normal"
          variant="outlined"
          placeholder="Enter Captcha"
          name="captchaInput"
          value={formik.values.captchaInput}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.captchaInput && Boolean(formik.errors.captchaInput)
          }
          helperText={formik.errors.captchaInput}
          inputProps={{
            maxLength: 6,
          }}
          autoComplete="off"
        />

        <CaptchaDisplay captcha={captcha} generateCaptcha={generateCaptcha} />

        <ButtonContainer>
          <LoadingButton loading={loading} onClick={formik.handleSubmit}>
            Verify DL
          </LoadingButton>

          <LoadingButton onClick={handleGoBack} backgroundColor="gray">
            Back
          </LoadingButton>
        </ButtonContainer>

        <MandatoryNote />
      </form>
    </OuterBox>
  );
};

export default MyComponent;
