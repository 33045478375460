import React from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  SubheaderSection,
  LoadingButton,
} from "./../../components";
const AppointmentDetails = ({ details }) => {
  const navigate = useNavigate();
  const handleDownloadPDF = () => {
    const pdfLink = details.digi_pass_url;
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = pdfLink;
    anchor.download = "document.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const GoBack = () => {
    navigate("/");
  };
  const theme = useTheme();
  return (
    <Box sx={{ padding: theme.spacing(3) }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            Reference ID:
          </Typography>
          <Typography variant="body1">{details.ref_id}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            appointment status:
          </Typography>
          <Typography variant="body1">{details.appointment_status}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            Requested Appointment Date:
          </Typography>
          <Typography variant="body1">{details.date}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            Meeting Start Time:
          </Typography>
          <Typography variant="body1">{details.start_time}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            Meeting End Time:
          </Typography>
          <Typography variant="body1">{details.end_time}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            Premise:
          </Typography>
          <Typography variant="body1">{details.office_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            department:
          </Typography>
          <Typography variant="body1">{details.department_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            officer name:
          </Typography>
          <Typography variant="body1">{details.officer_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            office address:
          </Typography>
          <Typography variant="body1">{details.office_address}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", alignItems: "left", mt: "16px", mb: "8px" }}>
        <SubheaderSection
          textAlign="left"
          title="Personal Details"
          variant="h3"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            align="left"
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            Your Name
          </Typography>
          <Typography variant="body1">{details.visitor_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            align="left"
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            Your email:
          </Typography>
          <Typography variant="body1">{details.email_id}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            align="left"
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            date of birth:
          </Typography>
          <Typography variant="body1">{details.dob}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            align="left"
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            Your Phone:
          </Typography>
          <Typography variant="body1">+{details.phone_number}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            state name:
          </Typography>
          <Typography variant="body1">{details.state_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
          <Typography variant="body1" align="left" fontWeight="bold">
            district name:
          </Typography>
          <Typography variant="body1">{details.district_name}</Typography>
        </Grid>
      </Grid>

      {/* <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="5px">
        <Box
          flex={1}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <SubmitButton
            onClick={GoBack}
            text="Back To Home"
            backgroundColor="gray"
          />
        </Box>

        <Box
          flex={1}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-end" }}
        >
          {details.digi_pass_url &&
            details.appointment_status === "approved" && (
              <SubmitButton
                onClick={handleDownloadPDF}
                text="Download DigiPass"
              />
            )}
        </Box>
      </Box> */}

      <ButtonContainer>
        {details.digi_pass_url && details.appointment_status === "approved" && (
          <LoadingButton onClick={handleDownloadPDF}>
            Download DigiPass
          </LoadingButton>
        )}

        <LoadingButton onClick={GoBack} backgroundColor="gray">
          Back To Home
        </LoadingButton>
      </ButtonContainer>
    </Box>
  );
};

export default AppointmentDetails;
