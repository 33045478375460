import React from "react";
import pages from "../../constants/pages";
import {
  OuterBox,
  SubheaderSection,
  MandatoryNote,
  LoadingButton,
  CommonTextField,
  SelectField,
} from "./../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const WelcomeScreen = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required("Booking Type is required"),
    referenceNo: Yup.string().required("Reference No is required"),
  });

  const onSubmit = async (values) => {
    const destination = `${pages.Success.route}?booking-type=${values.selectedOption}&ref-id=${values.referenceNo}&track-appointment=1`;
    navigate(destination);
  };

  const formik = useFormik({
    initialValues: {
      selectedOption: "",
      referenceNo: "",
    },
    validationSchema,
    onSubmit,
  });

  const bookingOptions = [
    { id: "appointment", name: "Track Appointment" },
    { id: "event", name: "Track Event" },
  ];

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title="Track Appointment"
        content="Select your preferred course of action"
      />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
          <SelectField
            label="Select Booking Type"
            name="selectedOption"
            value={formik.values.selectedOption}
            options={bookingOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.selectedOption && formik.errors.selectedOption
            }
            required
          />

          <CommonTextField
            label="Enter Refrence No"
            placeholder="Enter Refrence No"
            name="referenceNo"
            type="referenceNo"
            value={formik.values.referenceNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.referenceNo && Boolean(formik.errors.referenceNo)
            }
            required
          />
        </Grid>

        <LoadingButton onClick={formik.handleSubmit}>Proceed</LoadingButton>
        <MandatoryNote />
      </form>
    </OuterBox>
  );
};

export default WelcomeScreen;
