import React, { useState, useEffect } from "react";
import { OuterBox, SubheaderSection, LoadingButton } from "./../../components";
import { Loader } from "../../components";
import AppointmentDetails from "./AppointmentDetails";
import EventDetails from "./EventDetails";
import { useComponent } from "../../hooks/";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../constants/baseURL";
const AppointmentPage = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const refId = urlSearchParams.get("ref-id");
  const bookingType = urlSearchParams.get("booking-type");
  const TrackingAppointment = urlSearchParams.get("track-appointment");
  const [details, setDetails] = useState([]);
  const { loader } = useComponent();

  let header;
  let content;
  const baseURL = "mox_api";
  const url = BASE_URL[baseURL].url + BASE_URL[baseURL].version;
  useEffect(() => {
    loader.start();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}/booking/appointment/details?is_subevent=true&ref_id=${
            refId || sessionStorage.getItem("ref_id")
          }&booking_type=${bookingType}`
        );

        if (response.status) {
          setDetails(response.data.data);
          loader.stop();
        }
      } catch (error) {
        console.error("Error in fetching record", error);
        loader.stop();
      }
    };

    fetchData();
  }, [loader, refId, bookingType]);

  if (bookingType === "event" && details.length !== 0) {
    header = "Event Requested";
    content = "Your event booking request has been submitted successfully";
  } else if (bookingType === "appointment" && details.length !== 0) {
    header = "Appointment Requested";
    content =
      "Your appointment request has been submitted successfully. Concerned authority will revert ";
  } else {
    header = "Booking Not Found";
    content = "Please re-verify your booking REF ID";
  }

  const GoBack = async (values) => {
    const destination = `${pages.TrackAppointment.route}`;
    navigate(destination);
  };

  return (
    <Loader>
      <OuterBox>
        <SubheaderSection textAlign="left" title={header} content={content} />

        {bookingType === "appointment" && details.length !== 0 && (
          <AppointmentDetails details={details} />
        )}

        {bookingType === "event" && details.length !== 0 && (
          <EventDetails details={details} />
        )}

        {TrackingAppointment && details.length == 0 && (
          <LoadingButton onClick={GoBack} backgroundColor="gray">
            Retry
          </LoadingButton>
        )}
      </OuterBox>
    </Loader>
  );
};

export default AppointmentPage;
