import React, { Fragment, useState } from "react";
import {
  Loader,
  LoadingButton,
  OuterBox,
  SubheaderSection,
} from "../../../components";
import From from "./From";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Grid, List, ListItem, ListItemText } from "@mui/material";

function BioVerificaion() {
  const [digiPassQrReader, setDigiPassQrReader] = useState({
    qrReader: false,
    digipassQRid: "",
    digipassIMGid: "",
  });

  const [verification, setVerification] = useState({});

  return (
    <Fragment>
      <OuterBox>
        {!digiPassQrReader.qrReader && (
          <Fragment>
            <SubheaderSection
              textAlign="left"
              title="Bio-Metric Verification"
              content="Instructions for Visitors"
            />
            <List>
              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Allow access to camera, microphone and location." />
              </ListItem>

              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Make sure to have a stable Internet connection." />
              </ListItem>
              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Ensure proper lighting." />
              </ListItem>
              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Face the camera directly." />
              </ListItem>
              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Do not use a photo or video." />
              </ListItem>

              <ListItem sx={{ padding: "2px" }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#4CAF50", marginRight: "10px" }}
                />

                <ListItemText primary="Verify that the camera frame contains only one face." />
              </ListItem>
            </List>
            <LoadingButton
              onClick={() => {
                setDigiPassQrReader({ ...digiPassQrReader, qrReader: true });
                // console.log("oppo");
              }}
            >
              Scan QR Code
            </LoadingButton>
          </Fragment>
        )}
        <From
          digiPassQrReader={digiPassQrReader}
          verification={verification}
          setVerification={setVerification}
        />
      </OuterBox>
    </Fragment>
  );
}

export default BioVerificaion;
