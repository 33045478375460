import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles"; // Import makeStyles from @mui/styles
import Image1 from "./assets/img/registration_icon.png"; // Import the images
import Image2 from "./assets/img/applied_icon.png"; // Import the images
import Image3 from "./assets/img/issued_icon.png"; // Import the images
import { motion } from "framer-motion";
const data = [
  {
    image: Image1, // Replace with your image URL
    heading: "263518",
    subheading: "Total Appointment",
  },
  {
    image: Image2, // Replace with your image URL
    heading: "110354",
    subheading: "Digi Pass Applied",
  },
  {
    image: Image3, // Replace with your image URL
    heading: "99281",
    subheading: "Digi Pass Issued",
  },
];

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    justifyContent: "center",
    alignContent: "center",
    padding: theme.spacing(4), // Adjust spacing for top and bottom
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2), // Reduce padding for mobile
    },
  },
  columnImage: {
    width: "80px", // Adjust the image width as needed
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "60px", // Reduce image width for mobile
    },
  },
  columnText: {
    flex: 1,
  },
  heading: {
    color: "#5aacf6",
    fontSize: "64px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 700,
    padding: "0",
    margin: "0 0 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px", // Reduce font size for mobile
    },
  },
  subheading: {
    color: "#000",
    fontSize: "20px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 300,
    padding: "0",
    margin: "0 0 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px", // Reduce font size for mobile
    },
  },
  fadeInAnimation: {
    visibility: "visible",
    animationDelay: "0.1s",
    animationName: "fadeIn",
  },
}));

const ThreeColumnLayout = () => {
  const classes = useStyles();

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.2 } },
  };

  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visible">
      <Grid container sx={{ padding: "3rem" }}>
        {data.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Paper
              className={`${classes.columnContainer} ${classes.fadeInAnimation}`}
            >
              <img
                src={item.image}
                alt={`Image ${index}`}
                width="auto"
                className={`${classes.columnImage} ${classes.fadeInAnimation}`}
              />
              <div
                className={`${classes.columnText} ${classes.fadeInAnimation}`}
              >
                <Typography
                  variant="h5"
                  align="left"
                  gutterBottom
                  className={`${classes.heading} ${classes.fadeInAnimation}`}
                >
                  {item.heading}
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={`${classes.subheading} ${classes.fadeInAnimation}`}
                >
                  {item.subheading}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </motion.div>
  );
};

export default ThreeColumnLayout;
