import React, { useRef } from "react";

import {
  LoadingButton,
  ResendOTPButton,
  OTPInput,
  ButtonContainer,
} from "./../../components";

const OTPVerification = ({
  loading,
  otpLength,
  onSubmit,
  onResend,
  otp,
  setOTP,
  handleOtpGoBack,
}) => {
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < otpLength - 1) {
      newOTP[index + 1] = "";
      setOTP(newOTP);
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          margin: "10px",
          padding: "10px",
        }}
      >
        {otp.map((value, index) => (
          <OTPInput
            key={index}
            index={index}
            value={value}
            otp={otp}
            inputRefs={inputRefs}
            otpLength={otpLength}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>

      <ButtonContainer>
        <LoadingButton loading={loading} onClick={onSubmit}>
          Verify OTP
        </LoadingButton>

        <LoadingButton onClick={handleOtpGoBack} backgroundColor="gray">
          Back
        </LoadingButton>
      </ButtonContainer>

      <ResendOTPButton onClick={onResend} disabled={loading} />
    </form>
  );
};

export default OTPVerification;
