import { Grid, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";

import { LoadingButton, SubheaderSection } from "../../../components";

function ImgCapture({ bioFormikProps, onSubmit }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL("image/jpeg");
      bioFormikProps.setValues((prevValues) => ({
        ...prevValues,
        digipassIMGid: imageDataURL.replace(/^data:image\/[a-z]+;base64,/, ""),
      }));

      if (onSubmit) {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    startCamera();
  }, []);

  return (
    <Grid container display={"flex"} flexDirection={"column"}>
      <SubheaderSection
        textAlign="left"
        title="Capture Image For Verification"
        content="Please Bring The QR Code To The Display"
      />
      <Grid item position={"relative"} left={"17%"} width={"70%"}>
        <video id="video" width="100%" ref={videoRef} autoPlay></video>
      </Grid>

      <Grid item>
        <LoadingButton id="click-photo" type="button" onClick={takePhoto}>
          Click Photo
        </LoadingButton>
      </Grid>
      <canvas
        id="canvas"
        width="320"
        height="240"
        ref={canvasRef}
        style={{ display: "none" }}
      ></canvas>
    </Grid>
  );
}

export default ImgCapture;
