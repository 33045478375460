import React, { Fragment } from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  SubheaderSection,
  LoadingButton,
} from "./../../components";
import EventCard from "./../Booking/EventBooking/EventCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const AppointmentDetails = ({ details }) => {
  const navigate = useNavigate();
  const handleDownloadPDF = (pdfLink) => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = pdfLink;
    anchor.download = "document.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const GoBack = () => {
    navigate("/");
  };

  const theme = useTheme();

  return (
    <Fragment>
      <EventCard eventObject={details} handleDownloadPDF={handleDownloadPDF} />
      <Box sx={{ padding: theme.spacing(3) }}>
        <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Reference ID:
            </Typography>
            <Typography variant="body1">{details.ref_id}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              Booking Status:
            </Typography>
            <Typography variant="body1">{details.booking_status}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event name:
            </Typography>
            <Typography variant="body1">{details.event_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event Date:
            </Typography>
            <Typography variant="body1">{details.date}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event Start time:
            </Typography>
            <Typography variant="body1">{details.start_time}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event End time:
            </Typography>
            <Typography variant="body1">{details.end_time}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Venue:
            </Typography>
            <Typography variant="body1">{details.full_address}</Typography>
          </Grid>

          {details.map_link && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight="bold">
                Event Map:
              </Typography>
              <a
                href={details.map_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <LocationOnIcon style={{ marginRight: "4px" }} />
                Click Here
              </a>
            </Grid>
          )}
        </Grid>

        {/* <Divider sx={{ my: "16px" }}>
          <Box sx={{ display: "flex", alignItems: "left" }}>
            <Chip
              label="Personal Details"
              sx={{
                fontSize: "1.0625rem",
                fontWeight: 500,
                lineHeight: 1.25,
                letterSpacing: "0.5px",
              }}
            />
          </Box>
        </Divider> */}
        <Box
          sx={{ display: "flex", alignItems: "left", mt: "16px", mb: "8px" }}
        >
          <SubheaderSection
            textAlign="left"
            title="Personal Details"
            variant="h3"
          />
        </Box>

        <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            >
              Your Name
            </Typography>
            <Typography variant="body1">{details.visitor_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            >
              Your email:
            </Typography>
            <Typography variant="body1">{details.email_id}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            >
              date of birth:
            </Typography>
            <Typography variant="body1">{details.dob}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            >
              Your Phone:
            </Typography>
            <Typography variant="body1">+{details.phone_number}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              state name:
            </Typography>
            <Typography variant="body1">{details.state_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textTransform: "capitalize" }}>
            <Typography variant="body1" align="left" fontWeight="bold">
              district name:
            </Typography>
            <Typography variant="body1">{details.district_name}</Typography>
          </Grid>
        </Grid>

        {/* <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          gap="5px"
        >
          <Box
            flex={1}
            display="flex"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <SubmitButton
              onClick={GoBack}
              text="Back To Home"
              backgroundColor="gray"
            />
          </Box>

          <Box
            flex={1}
            display="flex"
            justifyContent={{ xs: "center", md: "flex-end" }}
          >
            {details.digi_pass_url && (
              <SubmitButton
                onClick={handleDownloadPDF}
                text="Download DigiPass"
              />
            )}
          </Box>
        </Box> */}

        <ButtonContainer>
          {details.digi_pass_url && details.booking_status === "approved" && (
            <LoadingButton
              onClick={() => handleDownloadPDF(details.digi_pass_url)}
            >
              Download DigiPass
            </LoadingButton>
          )}

          <LoadingButton onClick={GoBack} backgroundColor="gray">
            Back To Home
          </LoadingButton>
        </ButtonContainer>
      </Box>
    </Fragment>
  );
};

export default AppointmentDetails;
