import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { signInDetailsObject, validationSchema } from "./signInDetailsObject";

import { useAlert, useAxios } from "../../hooks";
import { OuterBox } from "../../components";
import { signInMember } from "../../redux/slices/memberSlice";

export default function Form() {
  const axios = useAxios({ baseURL: "mox_api_2", disableAuthToken: true });
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state } = useLocation();

  return (
    <OuterBox>
      <Formik
        enableReinitialize
        initialValues={signInDetailsObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          // console.log(formData, "formData");
          axios({
            url: "/agent/login",
            method: "POST",
            data: {
              username: formData.email,
              password: formData.password,
              agent_name: formData.agent_name,
            },
            disableRedirect: true,
          })
            .then(async (res) => {
              if (res.status) {
                sessionStorage.setItem("authToken", res.data.token);
                sessionStorage.setItem("displayName", res.data.agent_name);
                sessionStorage.setItem("eventID", res.data.event_id);
                await dispatch(
                  signInMember({
                    authToken: res.data.token,
                    agentName: res.data.agent_name,
                    eventID: res.data.event_id,
                  })
                );

                dispatch(alert({ message: "Welcome!", type: "success" }));

                navigate("/main-gate-entry-back");

                setSubmitting(false);
              } else {
                setSubmitting(false);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <FormDisplay formikProps={formikProps} />
          </form>
        )}
      </Formik>
    </OuterBox>
  );
}
