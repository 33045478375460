import React from "react";
import { Typography } from "@mui/material";

const MandatoryNote = () => (
  <Typography
    variant="body2"
    sx={{
      marginTop: "8px",
      paddingTop: "8px",
      textAlign: "left",
      color: "#777",
      width: "100%", // Set width to 100%
    }}
  >
    Note: Fields marked with <span style={{ color: "red" }}>*</span> are
    mandatory.
  </Typography>
);

export default MandatoryNote;
