import React, { useState, Fragment } from "react";
import MainContainer from "./Welcome";
import MobileVerification from "../MobileVerification";

import queryString from "query-string";
const FormPageLayout = () => {
  const URL = queryString.parse(window.location.search);
  const [procced, setProcced] = useState(URL.event_id ? true : false);
  const params = new URLSearchParams(window.location.search);

  if (params.has("event_id")) {
    sessionStorage.setItem("event_id", params.get("event_id"));
    if (params.has("email")) {
      sessionStorage.setItem("email", params.get("email"));
    } else {
      sessionStorage.removeItem("email");
    }
    if (params.has("mobile")) {
      sessionStorage.setItem("mobile", params.get("mobile"));
    } else {
      sessionStorage.removeItem("mobile");
    }
  } else {
    sessionStorage.removeItem("event_id");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("mobile");
  }

  const [selectedOption, setSelectedOption] = useState("Event Booking");
  return (
    <Fragment>
      {/* {procced && (
        <MainContainer
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setProcced={setProcced}
        />
      )} */}

      {!procced && (
        <MobileVerification
          setProcced={setProcced}
          selectedOption={selectedOption}
        />
      )}
    </Fragment>
  );
};

export default FormPageLayout;
