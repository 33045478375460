import AWS from "aws-sdk";
import imageCompression from "browser-image-compression";

const compressMedia = async (file, type = "image") => {
  if (type === "image") {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      const compressedFile = await imageCompression(file, options);
      // console.log(compressedFile.size / 1024 / 1024);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  return file;
};

AWS.config.update({
  region: "ap-south-1",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
  }),
});

export default async function AwsUpload(file, type) {
  try {
    const bucketName = "dc-chat-media";
    const fileMimeType = file.type.toLowerCase();
    let fileExtension = mimeTypeToExtension[fileMimeType] || ".txt";
    const filePath = `sample_data_files/${parseInt(
      new Date().getTime()
    )}${fileExtension}`;

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: filePath,
        Body: await compressMedia(file, type),
        ContentType: fileMimeType,
        ACL: "public-read",
      },
    });

    const data = await upload.promise();
    return data.Location;
  } catch (error) {
    console.error("Error uploading file to S3", error);
  }
}

const mimeTypeToExtension = {
  // Images
  "image/jpeg": ".jpg",
  "image/png": ".png",
  "image/gif": ".gif",
  "image/bmp": ".bmp",
  "image/webp": ".webp",
  "image/svg+xml": ".svg",
  "image/tiff": ".tiff",
  "image/x-icon": ".ico",

  // Videos
  "video/mp4": ".mp4",
  "video/ogg": ".ogg",
  "video/x-msvideo": ".avi",
  "video/3gpp": ".3gp",
  "video/quicktime": ".mov",
  "video/x-flv": ".flv",

  // Audio
  "audio/mpeg": ".mp3",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "audio/ogg": ".ogg",
  "audio/aac": ".aac",
  "audio/flac": ".flac",
  "audio/midi": ".midi",
  "audio/x-ms-wma": ".wma",

  // Documents
  "application/pdf": ".pdf",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ".pptx",
  "application/rtf": ".rtf",
  "text/plain": ".txt",
  "application/zip": ".zip",
  "application/x-gzip": ".gz",
  "application/x-tar": ".tar",
  "application/x-bzip2": ".bz2",
  "application/x-7z-compressed": ".7z",
  "application/x-rar-compressed": ".rar",

  // Fonts
  "font/woff": ".woff",
  "font/woff2": ".woff2",
  "font/otf": ".otf",
  "font/ttf": ".ttf",
  "font/eot": ".eot",

  // Blob Videos
  "video/blob": ".blob", // Adjust the extension as needed
  "video/webm": ".webm", // React Video Recorder Blob
  "video/webm;codecs=vp8,opus": ".webm",
};
