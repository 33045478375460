import React, { Fragment } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Vision from "./Vision";

const useStyles = makeStyles({
  visionBody: {
    fontSize: "17px",
    letterSpacing: "0.5px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 300,
    lineHeight: 1.5,
    marginBottom: "20px", // Add space between paragraphs
  },

  contactheading: {
    margin: "0",
    textAlign: "center",
    paddingBottom: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 600,
    fontFamily: '"Montserrat", sans-serif',
  },
});

const RowOfCards = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid
        sx={{
          paddingBottom: "15px",
          borderBottom: "1px solid #d6d6d6",

          marginTop: "10px",
          paddingTop: "20px",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            gutterBottom
            className={classes.contactheading}
          >
            About Us
          </Typography>
          <Typography variant="body1" className={classes.visionBody}>
            Welcome to the MPSeDC Digipass Solution, a revolutionary platform
            designed to streamline appointment bookings and event ticketing for
            visitors seeking interactions with officials of the Madhya Pradesh
            STATE ELECTRONICS DEVELOPMENT CORPORATION LTD (MPSeDC). Our advanced
            technology-driven system aims to provide a seamless and secure
            experience, enhancing communication, collaboration, and efficiency
            between visitors and officials.
          </Typography>
          <Typography variant="body1" className={classes.visionBody}>
            Whether you're a visitor seeking an appointment or an official
            managing appointments, MPSeDC Digipass Solution is committed to
            providing a seamless, secure, and efficient experience. Join us in
            embracing the digital transformation of appointment scheduling, and
            event participation, and be a part of the future of streamlined
            interactions.
          </Typography>
        </Container>
      </Grid>

      <Vision />
    </Fragment>
  );
};

export default RowOfCards;
