import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid, Button } from "@mui/material";
import { Label, TextField } from "../../components";

const StyledDialog = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ConfirmDialog = ({ confirm, setConfirm, formikProps }) => {
  const handleConfirmDialogClose = () => {
    setConfirm(false);
  };

  return (
    <StyledDialog
      open={confirm}
      onClose={handleConfirmDialogClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} sx={{ padding: "20px" }}>
          <Grid item container justifyContent="center" spacing={2} mt={2}>
            <Label formikProps={formikProps} title="Enter Your Reason" />
            <TextField formikProps={formikProps} name="reason" required />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => {
                setConfirm(false);
                formikProps.setValues((prev) => ({
                  ...prev,
                  isAllowed: false,
                }));
              }}
              sx={{ background: "grey" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={formikProps.handleSubmit}
              sx={{ marginLeft: "5px" }}
              disabled={!formikProps.isValid}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmDialog;
