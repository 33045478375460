import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  styled,
  Typography,
  Grid,
} from "@mui/material";
import logo from "./assets/DIGI-PASS-LOGO.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import { signOutMember } from "../../redux/slices/memberSlice";

import LOGO from "./assets/logo.png";

interface Props {
  window?: () => Window;
}

const drawerWidth = 260;
const navItems = [
  { text: "Home", link: "/" },
  { text: "About", link: "/about-us" },
  { text: "Appointment", link: "/appointment" },
  { text: "Track Appointment", link: "/track-appointment" },

  { text: "Contact", link: "/contact-us" },
  // { text: "Login", link: "/admin" },
];

const AppBarWithBluePart = styled(AppBar)({
  background: "white",
  boxShadow: "none",
  // height: "74px",
});

const Logo = styled("img")({
  width: 230,
  marginLeft: "20px",
});

const MenuItems = styled("div")({
  float: "right",
  "& a": {
    color: "#1E1E1E",
    fontWeight: 400,
    textDecoration: "none",
    fontSize: "17px",
    marginLeft: "1.5rem",
    position: "relative",
    "&:hover": {
      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-2px",
        height: "3px",
        background: "#107de2",
      },
    },
  },
});

const LogoBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  paddingRight: "3rem",
  maxWidth: "220px",
});

const BluePart = styled("div")({
  position: "absolute",
  top: 0,
  right: 0,
  height: "100%",
  maxWidth: 200,
  background: "blue",
  display: "flex",
  alignItems: "center",
  padding: "1.5rem",
});

const LoginButton = styled(Button)({
  color: "white",
  fontSize: "16px",
  padding: "0 1.5rem",
  fontFamily: "Open Sans, sans-serif",
});

export default function DrawerAppBar(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const match = [
    "/manual-entry",
    "/resend-notification",
    "/manual-verification",
    "/main-gate-entry-back",
  ].filter((item) => location.pathname == item);
  // console.log(match, "match");
  return (
    <Box sx={{ display: "flex", marginTop: "auto" }}>
      <CssBaseline />

      <Grid item container>
        <Grid item xs={3}></Grid>
        <Grid
          item
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
          }}
          xs={6}
        >
          <img className="logoImage" src={LOGO} style={{ marginTop: "10px" }} />
        </Grid>

        {!!match.length && (
          <Grid
            item
            container
            sx={{ display: "flex", justifyContent: "end", padding: "10px" }}
            xs={3}
          >
            <Button
              variant="contained"
              onClick={() => {
                dispatch(signOutMember());
                navigate("/sign-in");
              }}
              style={{ background: "red", height: "50%" }}
            >
              {" "}
              Log Out
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
