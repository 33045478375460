import React from "react";
import imageSrc from "./assests/error.png";
import { OuterBox, SubheaderSection, LoadingButton } from "./../../components";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledImage = styled("img")({
  maxWidth: "96px",
  height: "auto",
  textAlign: "center",
});

const MobileVerification = () => {
  const navigate = useNavigate();
  const handleRetryClick = () => {
    navigate("/");
  };

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title="Error"
        content=" Looks like there is some error in the previous steps, we request you
      to please retry"
      />

      <Box
        sx={{
          padding: "30px",
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <StyledImage src={imageSrc} alt="Your Image" />
      </Box>

      <form>
        <LoadingButton onClick={handleRetryClick}>RETRY</LoadingButton>
      </form>
    </OuterBox>
  );
};

export default MobileVerification;
