import React from "react";
import { Box, Paper } from "@mui/material";

const OuterBox = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "65vh",
      width: ["100%", "100%", "100%", "800px"],
      margin: "0 auto",
      padding: ["10px", "20px"],
      boxSizing: "border-box",
    }}
  >
    <Paper
      elevation={3}
      sx={{
        width: "100%",
        padding: "20px",
        // boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
        border: "2px solid #a3a3a3",
        borderRadius: "15px",
        minHeight: "20vh",
        alignContent: "center",
      }}
    >
      {children}
    </Paper>
  </Box>
);

export default OuterBox;
