import React, { Fragment, useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, Grid, Paper, Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateBeforeIcon from "@mui/icons-material/WestSharp";
import NavigateNextIcon from "@mui/icons-material/EastSharp";
import { Autoplay } from "swiper/modules";
const CardContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  borderRadius: "20px",
}));

const TitlePaper = styled(Paper)({
  backgroundColor: "#107de2",
  color: "#fff",
  borderRadius: "22px",
  width: "114px",
  height: "36px",
  fontSize: "1rem",
  padding: "8px",
  position: "absolute",
  top: "-2ex",
  zIndex: "1",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "500",
});

const HeadingPaper = styled(Paper)({
  backgroundColor: "#107de2",
  textAlign: "center",
  width: "100%",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 500,
  color: "#fff",
  margin: "0px",
  padding: "15px 15px",
  fontSize: "24px",
});

const useStyles = makeStyles((theme) => ({
  FAQHeading: {
    margin: "10px",
    padding: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 300,
    fontFamily: '"Montserrat", sans-serif',
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // width: "50px",
    color: "#107de2",
    fontSize: "45px",
  },
  swiperWrapper: {
    display: "flex",
    flex: "1",
    width: "80vw",
    alignItems: "baseline",
  },
}));

const SubHeadingPaper = styled(Typography)({
  textAlign: "left",
  width: "100%",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 500,
  color: "#474747",
  fontSize: "12px",
  padding: "15px 15px 10px 15px",
});

const CardSlider = ({ backgroundColor, cardData, heading }) => {
  const classes = useStyles();

  const swiperRef = useRef(null);
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [isMobile, setIsMobile] = useState(false);
  const handlePrevButtonClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextButtonClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 600) {
        setIsMobile(true);
        setSlidesPerView(1);
      } else if (windowWidth <= 1024) {
        setIsMobile(false);
        setSlidesPerView(2);
      } else {
        setIsMobile(false);
        setSlidesPerView(4);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <Grid
        container
        sx={{
          padding: "40px 0px",
          backgroundColor: backgroundColor,
          borderBottom: "1px solid #d6d6d6",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1" className={classes.FAQHeading}>
          {heading}
        </Typography>

        <div className={classes.navigationContainer}>
          <div className={classes.iconContainer}>
            {isMobile ? null : (
              <NavigateBeforeIcon
                onClick={handlePrevButtonClick}
                sx={{ fontSize: "45px" }}
              />
            )}
          </div>
          <div className={classes.swiperWrapper}>
            <Swiper
              ref={swiperRef}
              slidesPerView={slidesPerView}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {cardData.map((card, index) => (
                <SwiperSlide key={index} style={{ height: "440px" }}>
                  <CardContainer
                    sx={{ position: "relative", padding: "15px 15px" }}
                  >
                    <Paper
                      sx={{
                        border: "1px solid #107de2",
                        borderRadius: "30px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <TitlePaper>{card.title}</TitlePaper>
                      <img
                        src={card.imageSrc}
                        alt={card.title}
                        style={{ margin: "30px auto" }}
                      />
                      <HeadingPaper>{card.heading}</HeadingPaper>
                      <SubHeadingPaper>{card.subheading}</SubHeadingPaper>
                    </Paper>
                  </CardContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={classes.iconContainer}>
            {isMobile ? null : (
              <NavigateNextIcon
                onClick={handleNextButtonClick}
                sx={{ fontSize: "45px" }}
              />
            )}
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

export default CardSlider;
