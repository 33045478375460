import { Fragment } from "react";
import { Formik } from "formik";
import { QrReader } from "react-qr-reader";
import { Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import ImgCapture from "./ImgCapture";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, SubheaderSection } from "../../../components";

function From({ digiPassQrReader, verification, setVerification }) {
  const axios = useAxios();

  const { loader } = useComponent();

  return (
    <Fragment>
      <Formik
        initialValues={digiPassQrReader}
        enableReinitialize
        onSubmit={(formData) => {
          let formDatas = {
            id: formData.digipassQRid,
            face_image: formData.digipassIMGid,
            machine_id: "TW30001022000470",
          };
          loader.start();
          axios({
            url: "/verify/verify_visitor",
            method: "POST",
            data: formDatas,
            customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
            disableRedirect: true,
            disableError: true,
          }).then((response) => {
            // console.log(response.status);

            setVerification(response);

            loader.apiComplete();
          });
        }}
      >
        {(bioFormikProps) => {
          return (
            <Loader>
              {bioFormikProps.values.qrReader &&
                bioFormikProps.values.digipassQRid === "" && (
                  <Fragment>
                    <SubheaderSection
                      textAlign="left"
                      title=" Scan QR Code"
                      content="Please Bring The QR Code To The Display"
                    />
                    <Grid
                      sx={{ width: "70%" }}
                      position={"relative"}
                      left={"17%"}
                    >
                      <QrReader
                        onResult={(result, error) => {
                          if (!!result) {
                            bioFormikProps.setValues((prevVal) => ({
                              ...prevVal,
                              digipassQRid: result?.text,
                              qrReader: false,
                            }));
                          }

                          if (!!error) {
                            console.info(error);
                          }
                        }}
                        scanDelay={500}
                      />
                    </Grid>
                  </Fragment>
                )}
              {bioFormikProps.values.digipassQRid !== "" &&
                !bioFormikProps.isSubmitting && (
                  <ImgCapture
                    bioFormikProps={bioFormikProps}
                    onSubmit={bioFormikProps.handleSubmit}
                  />
                )}

              {bioFormikProps.values.digipassIMGid !== "" &&
              bioFormikProps.values.digipassQRid !== "" ? (
                <Fragment>
                  {verification.status ? (
                    <Grid container sx={{ p: "50px" }} spacing={5}>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            color: "#4CAF50",
                            marginRight: "10px",
                            fontSize: "150px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubheaderSection
                          textAlign="Center"
                          title="ACCESS GRANTED"
                          content={"Please Proceed to Enter"}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container sx={{ p: "50px" }} spacing={5}>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <DoDisturbIcon
                          sx={{
                            color: "#fb0606",
                            marginRight: "10px",
                            fontSize: "150px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubheaderSection
                          textAlign="Center"
                          title="ACCESS DENIED"
                          content={
                            verification.appointment_status
                              ? "QR Code Not Found"
                              : "Face Verification Failed"
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              ) : (
                ""
              )}
            </Loader>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default From;
