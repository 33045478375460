import React, { Fragment } from "react";
import { Card, CardContent, Typography, Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import Image from "./assets/img/getyourpass2.png"; // Import the images
const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "20px",
  },
  cardsSection: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    width: "100%",

    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  subHeading: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    color: "#474747",
    fontSize: "12px",
  },
  heading: {
    backgroundColor: "#107de2",
    color: "#fff",
    fontSize: "20px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
    padding: "8px",
    marginBottom: "20px",
  },
  visionTitle: {
    margin: "10px",
    padding: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 300,
    fontFamily: '"Montserrat", sans-serif',
  },
  visionBody: {
    fontSize: "17px",
    letterSpacing: "0.5px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 300,
    lineHeight: 1.5,
  },

  getYourPassWrap: {
    padding: "88px 0",
    //position: "relative",
    background: `url(${Image}) top center no-repeat`,
    backgroundSize: "cover",
  },

  contactheading: {
    margin: "0",
    textAlign: "center",
    paddingBottom: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 600,
    fontFamily: '"Montserrat", sans-serif',
  },
  info: {
    fontSize: "20px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
};

const cardsData = [
  {
    title: "User-Centric Experience",
    subheading:
      "Understanding the diverse needs of our users, we have meticulously crafted a range of user-facing components that cater to the distinct requirements of visitors and officials. Our intuitive website offers options for appointment booking through a chatbot interface or a form, providing flexibility and ease of use. Meanwhile, our admin dashboard empowers officials with comprehensive tools to manage appointments efficiently, ensuring effective coordination and decision-making.",
  },
  {
    title: "Cutting-Edge Security",
    subheading:
      "Security and privacy are paramount in our system. We employ advanced identity verification methods, including Aadhar validation and face match technology, to ensure the authenticity of each user. Our secure e-gates equipped with QR code scanning and face match capabilities further enhance premises entry verification, guaranteeing a secure environment for both visitors and officials.",
  },
  {
    title: "Community Engagement",
    subheading:
      "Beyond appointments, we extend our services to the community by facilitating ticket bookings for a variety of government-hosted events. From cultural festivals to educational conferences, MPSeDC Digipass Solution encourages community involvement and active participation, fostering a sense of civic pride and connection.",
  },
];

const RowOfCards = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid
          sx={{
            backgroundColor: "#e2f1ff",
            paddingBottom: "15px",
            borderBottom: "1px solid #d6d6d6",
          }}
        >
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.content}>
              <Typography variant="h3" className={classes.visionTitle}>
                Our Vision
              </Typography>
              <Typography variant="body1" className={classes.visionBody}>
                At MPSeDC Digipass, our vision is to empower both visitors and
                officials with a user-centric approach to appointment scheduling
                and event participation. We strive to create a digital ecosystem
                that simplifies processes, ensures identity verification, and
                enables convenient access to MPSeDC premises and government-led
                events.
              </Typography>
            </div>

            <div className={classes.cardsSection}>
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                {cardsData.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Typography variant="h6" className={classes.heading}>
                          {card.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.subHeading}
                        >
                          {card.subheading}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
          {/* </Grid>

        <Grid className={classes.getYourPassWrap}> */}
          <Container sx={{}}>
            <Card
              sx={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                display: "flex",
                flexDirection: "column",
                margin: "40px auto",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FFF",
                padding: "25px",

                border: "1px solid #107de2",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                className={classes.contactheading}
              >
                Contact Information
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "17px",
                  letterSpacing: "0.5px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 300,
                  lineHeight: 1.5,
                  textAlign: "center",
                }}
              >
                For any inquiries or assistance, please don't hesitate to
                contact our support team. We're here to ensure your experience
                with us is exceptional.
              </Typography>

              <div className={classes.centerText}>
                <Typography variant="h5" className={classes.info}>
                  Corporate Office (Bhopal)
                </Typography>

                <Typography variant="h6" className={classes.info}>
                  MPSeDC Ltd. State IT Centre, 47-A, Arera Hills, Bhopal-462011
                  (M.P.)
                </Typography>
                <Typography variant="h6" className={classes.info}>
                  Telephone: +91 755 2518300, +91 755 2518500
                </Typography>
                <Typography variant="h6" className={classes.info}>
                  Fax: +91 755 2579824
                </Typography>
              </div>
            </Card>
          </Container>
        </Grid>
      </motion.div>
    </Fragment>
  );
};

export default RowOfCards;
