import { Button, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ConfirmDialog from "./Confirm";

const UserDetails = ({ formikProps }) => {
  const [confirm, setConfirm] = useState(false);

  const {
    userDetails: { appointment_data, appointment_status },
  } = formikProps.values;

  const renderRow = (label, value) => (
    <tr>
      <td style={styles.label}>{label}</td>
      <td style={styles.value}>{value}</td>
    </tr>
  );

  return (
    <Fragment>
      <Grid container justifyContent="center">
        <Grid
          item
          container
          justifyContent={"center"}
          flexDirection={"column"}
          width={"30%"}
        >
          <Grid item sx={styles.imageContainer}>
            <img
              src={
                appointment_data?.face_data
                  ? `data:image/png;base64, ${appointment_data.face_data}`
                  : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
              }
              width="100%"
              height="100%"
              style={styles.image}
              alt="User"
            />
          </Grid>

          <Grid item sx={styles.detailsContainer}>
            <table style={{ width: "100%" }}>
              <tbody>
                {renderRow(
                  "Name",
                  `${appointment_data?.first_name} ${appointment_data?.last_name}`
                )}
                {renderRow("Phone Number", appointment_data?.mobile_number)}
                {renderRow("Email ID", appointment_data?.email)}
                {renderRow("Booking Id", appointment_data?.booking_id)}
                {renderRow(
                  "Appointment Status",
                  appointment_status ? (
                    <CheckCircleOutlineRoundedIcon
                      sx={styles.statusIcon(true)}
                    />
                  ) : (
                    <CancelRoundedIcon sx={styles.statusIcon(false)} />
                  )
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2} mt={2}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={styles.declineButton}
              onClick={() => {
                setConfirm(true);
                formikProps.setValues((prev) => ({
                  ...prev,
                  isAllowed: false,
                }));
              }}
              fullWidth
            >
              Decline
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                formikProps.setValues((prev) => ({
                  ...prev,
                  isAllowed: true,
                }));
                formikProps.handleSubmit();
              }}
            >
              Allow
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        confirm={confirm}
        setConfirm={setConfirm}
        formikProps={formikProps}
      />
    </Fragment>
  );
};

const styles = {
  imageContainer: {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    boxShadow: "rgb(45, 151, 131) 0px -2px 8px 0px",
  },
  image: {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  detailsContainer: {
    boxShadow: "0px 1px 8px 0px rgb(45, 151, 131)",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  label: {
    padding: "5px",
    fontWeight: 600,
    color: "#7e7e7e",
  },
  value: {
    padding: "5px",
    fontWeight: 600,
    wordBreak: "break-word",
  },
  statusIcon: (isSuccess) => ({
    fontSize: "30px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: isSuccess ? "green" : "red",
  }),
  declineButton: {
    background: "red",
    "&:hover": { background: "red" },
  },
};

export default UserDetails;
