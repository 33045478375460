const pages = {
  appointment: {
    ID: "appointment",
    route: "/appointment",
  },

  TrackAppointment: {
    ID: "TrackAppointment",
    route: "/track-appointment",
  },

  ContactUs: {
    ID: "ContactUs",
    route: "/contact-us",
  },

  AboutUs: {
    ID: "AboutUs",
    route: "/about-us",
  },

  previewVideo: {
    ID: "previewVideo",
    route: "/previewVideo",
  },

  checkLiveliness: {
    ID: "liveliness",
    route: "/booking-verification",
  },

  Success: {
    ID: "Success",
    route: "/success",
  },

  Verification: {
    ID: "Verification",
    route: "/verification",
  },

  AadharverificaionDetails: {
    ID: "AadharverificaionDetails",
    route: "/aadhar-verificaion-details",
  },

  AadharverificaionSuccess: {
    ID: "AadharverificaionSuccess",
    route: "/aadhar-verificaion-success",
  },

  DrivingLicenceDetails: {
    ID: "DrivingLicenceDetails",
    route: "/driving-licence-details",
  },

  UploadDocument: {
    ID: "UploadDocument",
    route: "/upload-document",
  },

  EventBooking: {
    ID: "EventBooking",
    route: "/event-booking",
  },

  AppointmentBooking: {
    ID: "AppointmentBooking",
    route: "/appointment-booking",
  },

  EventBookingDetails: {
    ID: "EventBookingDetails",
    route: "/event-booking-details",
  },

  AppointmentBookingDetail: {
    ID: "AppointmentBooking",
    route: "/appointment-booking-details",
  },

  BioVerificaion: {
    ID: "AppointmentBooking",
    route: "/bio-verification",
  },

  // MainGateEntryWithFrontCamera: {
  //   ID: "MainGateEntryWithFrontCamera",
  //   route: "/main-gate-entry-front",
  // },

  MainGateEntryWithBackCamera: {
    ID: "MainGateEntryWithBackCamera",
    route: "/main-gate-entry-back",
  },

  MainGateEntrySuccess: {
    ID: "MainGateEntrySuccess",
    route: "/main-gate-entry-success",
  },

  ManualEntry: {
    ID: "ManualEntry",
    route: "/manual-entry",
  },

  ManualVerification: {
    ID: "ManualVerification",
    route: "/manual-verification",
  },

  ResendNotification: {
    ID: "ResendNotification",
    route: "/resend-notification",
  },
};
export default pages;
