import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import Image2 from "./assets/banner/banner2.png"; // Import the images

function CarouselComponent() {
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen",
      image: Image2,
    },
    {
      name: "Random Name #2",
      description: "Hello World",
      image: Image2,
    },
  ];

  return (
    <Carousel
      animation="slide"
      duration={2000}
      navButtonsAlwaysVisible={true}
      navButtonsProps={{
        style: {
          backgroundColor: "transparent",
          border: "1px solid #FFF",
          fontSize: "22px",
          padding: "12px",
        },
      }}
      indicators={false}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const paperStyle = {
    boxShadow: "none",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <Paper className="carousel-item" style={paperStyle}>
      <img
        src={props.item.image}
        alt={props.item.name}
        style={imageStyle}
        loading="lazy"
      />
    </Paper>
  );
}

export default CarouselComponent;
