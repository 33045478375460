import { Fragment, useRef } from "react";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import { OuterBox, LoadingButton, SubheaderSection } from "./../../components";

export default function UploadDocument() {
  const navigate = useNavigate();

  const [mimeType, setMimeType] = useState("video/webm;codecs=vp8,opus");
  const axiosDigipass = useAxios({ baseURL: "digipass" });

  const location = useLocation();
  const { visitor_id, id, ref_id, booking_type } = location.state || {};

  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get("booking_id");
  const eventId = queryParams.get("event_id");

  const [error, setError] = useState("");

  useEffect(() => {
    if (eventId) {
      sessionStorage.setItem("eventID", eventId);
      sessionStorage.setItem("bookingID", bookingId);
    }

    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else if (window.navigator.userAgent.indexOf("Mac OS") !== -1) {
      setMimeType("video/mp4");
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else {
      setMimeType("video/webm;codecs=vp8,opus");
    }

    axiosDigipass({
      url:
        "/verify/booking-status/" +
        (eventId || sessionStorage.getItem("eventID")),
      method: "POST",
      data: { booking_id: sessionStorage.getItem("bookingID") },
      disableRedirect: true,
    }).then((response) => {
      if (response?.status) {
        !response.data.is_valid && setError(response.message.displayMessage);
      }
    });
  }, []);

  const [startRecording, setStartRecording] = useState(false);

  //const userDetails = useSelector((state) => state.userIdentityDetails);

  const verificationContent = startRecording
    ? ""
    : "Next, Please read the following guidelines then proceed to Liveness Check:";

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 620,
          zoom: true,
        },
        audio: false,
      });
      setStream(stream);
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null); // or streamRef.current = null;
    }
  };

  const takePhoto = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (
      (canvas.width >= 150 || canvas.width <= 300) &&
      (canvas.height >= 600 || canvas.height <= 1200)
    ) {
      if (video && canvas) {
        const context = canvas.getContext("2d");

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const captureSize = 350;
        const sourceX = (videoWidth - captureSize) / 2;
        const sourceY = (videoHeight - captureSize) / 2;

        context.drawImage(
          video,
          sourceX,
          sourceY,
          captureSize,
          captureSize,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const imageDataURL = canvas.toDataURL("image/jpeg");

        navigate(pages.previewVideo.route, {
          state: {
            blob: imageDataURL,
            visitor_id: visitor_id,
            id: id,
            bookingId: bookingId,
            eventId: eventId,
            ref_id: ref_id,
            booking_type: booking_type,
          },
        });
        stopCamera();
      }
    }
  };

  return (
    <Fragment>
      {Boolean(error) ? (
        <OuterBox>
          <Grid item container justifyContent={"center"}>
            <CancelRoundedIcon
              sx={{ fontSize: "70px", color: "red", marginBottom: "15px" }}
            />
          </Grid>

          <SubheaderSection
            textAlign="center"
            variant="h3"
            title={error ?? error?.toUpperCase()}
          />
        </OuterBox>
      ) : (
        <OuterBox>
          <SubheaderSection
            textAlign="left"
            title="Face Verification Initiated"
            content={verificationContent}
          />

          <form>
            {!startRecording ? (
              <React.Fragment>
                <div>
                  <div>
                    <List>
                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Allow access to camera, microphone and location." />
                      </ListItem>

                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Make sure to have a stable Internet connection." />
                      </ListItem>
                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Ensure proper lighting." />
                      </ListItem>
                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Face the camera directly." />
                      </ListItem>
                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Do not use a photo ." />
                      </ListItem>

                      <ListItem sx={{ padding: "2px" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#4CAF50", marginRight: "10px" }}
                        />

                        <ListItemText primary="Verify that the camera frame contains only one face." />
                      </ListItem>
                    </List>
                  </div>
                </div>
                <LoadingButton
                  onClick={() => {
                    startCamera();
                    setStartRecording(true);
                  }}
                >
                  Launch Camera
                </LoadingButton>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid
                  container
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    className="videoBox"
                    position={"relative"}
                    // left={"17%"}
                    width={"30%"}
                  >
                    <svg id="svg-overlay" viewBox="0 0 100 130">
                      <path
                        d="M50 5
                 C 25 5, 5 30, 5 65
                 C 5 90, 15 110, 35 120
                 C 40 123, 45 125, 50 125
                 C 55 125, 60 123, 65 120
                 C 85 110, 95 90, 95 65
                 C 95 30, 75 5, 50 5"
                        fill="none"
                        stroke="#ff438e"
                        stroke-width="1.5"
                      />
                    </svg>

                    <video
                      id="video"
                      width="100%"
                      height="100%"
                      style={{ objectFit: "initial" }}
                      ref={videoRef}
                      muted
                      autoPlay
                      playsInline
                    ></video>
                  </Grid>

                  <Grid item display={"flex"} justifyContent={"center"}>
                    <LoadingButton
                      id="click-photo"
                      type="button"
                      onClick={takePhoto}
                    >
                      Click Photo
                    </LoadingButton>
                  </Grid>
                  <canvas
                    id="canvas"
                    width="480"
                    height="600"
                    ref={canvasRef}
                    style={{ display: "none" }}
                  ></canvas>
                </Grid>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ textAlign: "center", padding: "10px" }}
                  fontWeight="bold"
                >
                  Make sure to verify the image before submitting
                </Typography>
              </React.Fragment>
            )}
          </form>
        </OuterBox>
      )}
    </Fragment>
  );
}
