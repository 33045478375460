import React, { useState } from "react";
import { OuterBox, LoadingButton, SubheaderSection } from "./../../components";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useNavigate } from "react-router-dom";
import pages from "./../../constants/pages";
import { useAlert, useAxios } from "./../../hooks/";
import AwsUpload from "./../../utils/AwsUpload";

const MyComponent = ({
  setDlVerified,
  token,
  bookingType,
  visitorId,
  id,
  identityType,
}) => {
  const { alert } = useAlert();

  const [loading, setLoading] = useState(false);
  // const [details, setDetails] = useState({});
  const navigate = useNavigate();
  const [documentFileName, setDocumentFileName] = useState(null);
  // const [documentFile, setDocumentFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    documentFile: Yup.mixed().required("Document file is required"),
  });

  const handleFileChange = async (event) => {
    try {
      setUploadLoading(true);
      const file = event.target.files[0];
      setDocumentFileName(file.name);
      const location = await AwsUpload(file);
      console.log(location);
      formik.setValues({
        ...formik.values,
        documentFile: location,
      });
      setUploadLoading(false);

      dispatch(
        alert({
          type: "success",
          message: "file Upload successful",
        })
      );
    } catch (error) {
      dispatch(
        alert({
          type: "success",
          message: "Error in file uploading",
        })
      );
      console.error("Error uploading file to S3", error);
    }
  };

  const dispatch = useDispatch();

  const axios = useAxios();

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const payload = {
        visitor_id: visitorId,
        doc_image: values.documentFile,
        booking_type: bookingType,
        doc_type: identityType,
        is_verified: 0,
        id: id,
      };

      const response = await axios({
        url: "/booking/appointment/verification",
        method: "PATCH",
        data: payload,
      });

      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: response.message.displayMessage,
          })
        );

        payload.is_verified = 0;
        const payloadString = JSON.stringify(payload);

        localStorage.setItem("userPayload", payloadString);
        setLoading(false);

        const destination = `${pages.Success.route}?ref-id=${response.data.ref_id}&booking-type=${bookingType}`;
        navigate(destination);
      }
    } catch (error) {
      console.error("Error in fetching department list", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      documentFile: null,
    },
    validationSchema,
    onSubmit,
  });

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title=" Upload Verification Document"
        content=" To manually verify your identity, kindly upload a verification
          document"
      />

      <form>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ marginBottom: "16px" }}
          error={
            formik.touched.documentFile && Boolean(formik.errors.documentFile)
          }
        >
          <label htmlFor="documentFile">
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              Upload Verification Document
            </Typography>
            <LoadingButton
              loading={uploadLoading}
              component="span"
              variant="contained"
              startIcon={<AttachFileIcon />}
              float="left"
            >
              {documentFileName ? documentFileName : "Select File"}
            </LoadingButton>
          </label>
          <input
            type="file"
            id="documentFile"
            name="documentFile"
            onChange={handleFileChange}
            accept=".pdf,.jpeg,.png"
            style={{ display: "none" }}
          />
          {formik.touched.documentFile && formik.errors.documentFile && (
            <Typography variant="body2" color="error">
              {formik.errors.documentFile}
            </Typography>
          )}
        </FormControl>

        <LoadingButton
          loading={loading}
          onClick={formik.handleSubmit}
          disabled={!formik.values.documentFile || uploadLoading || loading}
        >
          Proceed
        </LoadingButton>
      </form>
    </OuterBox>
  );
};

export default MyComponent;
