import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import DrivingLicence from "./DrivingLicence/DrivingLicence";
import { useAxios, useAxiosV2, useComponent } from "../../hooks/";
import Aadharverificaion from "./Aadharverificaion/Aadharverificaion";
import UploadDocument from "../../pages/UploadDocument/UploadDocument";
import { OuterBox, SubheaderSection, LoadingButton } from "./../../components";
import pages from "../../constants/pages";

const WelcomeScreen = ({ setAadharVerified }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const axiosVerismart = useAxios({ baseURL: "mox_api_2" });
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });
  const [token, setToken] = React.useState("");
  const [verification, setVerification] = useState(false);
  const [identityType, setIdentityType] = useState("dl");

  const [verificationFailedCount, setVerificationFailedCount] =
    React.useState(0);
  const handleProceed = () => {
    setVerification(true);
  };
  const handleIdentityTypeChange = (event) => {
    setIdentityType(event.target.value);
  };

  const queryParams = new URLSearchParams(location.search);
  const visitorId = queryParams.get("visitor_id");

  if (visitorId !== null) {
    sessionStorage.setItem("visitorId", visitorId);
  }
  const id = queryParams.get("id");
  if (id !== null) {
    sessionStorage.setItem("id", id);
  }
  const bookingType = queryParams.get("booking_type");
  const { loader } = useComponent();
  const [bookingStatus, setBookingStatus] = useState({
    booking_already_verified: false,
    booking_exists: undefined,
    face_match: false,
    name_match: false,
    qr_link: "",
  });

  useEffect(() => {
    axiosVerismart({
      url: `/booking/verification-status`,
      method: "POST",
      disableRedirect: true,
      data: {
        name: "",
        face_data: "",
        booking_id: visitorId,
      },
    }).then((response) => {
      if (response.status) {
        setBookingStatus(response.data);
      } else {
        setBookingStatus(response.data);
      }
    });

    loader.start();

    axiosAadhar({
      url: `/login/`,
      method: "POST",
      data: {
        client_secret: "dmVyaXNtYXJ0",
      },
    })
      .then((response) => {
        if (response.success) {
          setToken(response.access_token);
          loader.stop();
        } else {
          dispatch(
            alert({
              type: "error",
              message: "Token generation failed",
            })
          );
          loader.stop();
        }
      })
      .catch((error) => {
        console.error("Error in token generation ", error);
      });
  }, []);

  if (verificationFailedCount > 4) {
    window.location.reload();
  }

  if (!verification) {
    return (
      <Fragment>
        {bookingStatus?.booking_exists === undefined && (
          <Fragment>
            <OuterBox>
              <Grid
                container
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <CircularProgress sx={{ color: "green" }} />
                <Typography variant="h6" sx={{ mt: "30px" }} fontWeight={"600"}>
                  Please wait....
                </Typography>
              </Grid>
            </OuterBox>
          </Fragment>
        )}

        {bookingStatus?.booking_exists !== undefined &&
          bookingStatus?.booking_exists &&
          !bookingStatus?.booking_already_verified && (
            <OuterBox>
              <SubheaderSection
                textAlign="left"
                title="Document Verification"
                content=" Choose an Identification Document from the Options Below"
              />

              <form>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: "16px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Document
                  </InputLabel>
                  <Select
                    label="Select Booking Type"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    onChange={handleIdentityTypeChange}
                    value={identityType}
                    name="identityType"
                  >
                    <MenuItem value="dl">Driving License</MenuItem>
                    <MenuItem value="aadhaar">Aadhar Card</MenuItem>
                  </Select>
                </FormControl>
                <LoadingButton onClick={handleProceed}>Proceed</LoadingButton>
              </form>
            </OuterBox>
          )}
        {bookingStatus?.booking_exists !== undefined &&
          !bookingStatus?.booking_exists &&
          !bookingStatus?.booking_already_verified && (
            <OuterBox>
              <Grid item container justifyContent={"center"}>
                <CancelRoundedIcon
                  sx={{ fontSize: "40px", color: "red", marginBottom: "15px" }}
                />
              </Grid>

              <SubheaderSection textAlign="center" title="Booking Not Found" />
            </OuterBox>
          )}

        {bookingStatus?.booking_already_verified && (
          <OuterBox>
            <Grid item container justifyContent={"center"}>
              <CheckCircleOutlineRoundedIcon
                sx={{
                  fontSize: "80px",
                  lineHeight: "21px",
                  textAlign: "center",
                  textTransform: "uppercase",
                  color: "rgb(41 128 31)",
                  marginBottom: "30px",
                }}
              />
            </Grid>

            <SubheaderSection
              textAlign="center"
              title="The booking process is finished and has been successfully verified."
            />
          </OuterBox>
        )}
      </Fragment>
    );
  }

  if (identityType === "aadhaar" && token) {
    return (
      <Aadharverificaion
        setAadharVerified={setAadharVerified}
        token={token}
        visitorId={visitorId}
        bookingType={bookingType}
        id={id}
        setVerificationFailedCount={setVerificationFailedCount}
        verificationFailedCount={verificationFailedCount}
        setVerification={setVerification}
      />
    );
  }

  if (identityType === "dl" && token) {
    return (
      <DrivingLicence
        token={token}
        visitorId={visitorId}
        bookingType={bookingType}
        id={id}
        setVerificationFailedCount={setVerificationFailedCount}
        verificationFailedCount={verificationFailedCount}
        setVerification={setVerification}
      />
    );
  }
};

export default WelcomeScreen;
