/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

//Misc Pages

//Test Page

const emptyLayoutRoutes = {
  //Error 404
};

export default emptyLayoutRoutes;
