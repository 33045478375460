export default function convertNormalDate(timeStamp) {
  var date;

  if (typeof timeStamp === "string") {
    // Parse the custom timestamp
    const match = timeStamp.match(/(\w+) (\w+) (\d{1,2}) (\d{4})/);
    if (match) {
      const [, , month, day, year] = match;
      const monthAbbreviations = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
      };
      date = new Date(parseInt(year), monthAbbreviations[month], parseInt(day));
    }
  }

  if (!date || isNaN(date.getTime())) {
    date = new Date(timeStamp);
  }

  if (isNaN(date.getTime())) {
    return null;
  }

  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  var time = day + "-" + month + "-" + year;

  return time;
}
