import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Grid, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  OuterBox,
  LoadingButton,
  SubheaderSection,
  MandatoryNote,
  SelectField,
  CommonTextField,
  TextField,
} from "./../../../components";
import EventCard from "./EventCard";
import { Loader } from "../../../components";
import pages from "./../../../constants/pages";
import DocumentUpload from "./../DocumentUpload";
import { useAlert, useAxios, useComponent } from "../../../hooks/";
import { RAZORPAY_KEY } from "../../../config/config";

const MyComponent = () => {
  const axiosApi = useAxios();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loader } = useComponent();

  const { mobile, id } = useParams();

  const [loading, setLoading] = useState(false);

  const [eventObject, setEventObject] = useState([]);

  const [stateOptions, setStateOptions] = useState([]);

  const [eventOptions, setEventOptions] = useState([]);

  const [uploadLoading, setUploadLoading] = useState(false);

  const [subEventOptions, setSubEventOptions] = useState([]);

  const [districtOptions, setDistrictOptions] = useState([]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const onSubmit = async (values) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    axiosApi({
      url: "/booking/appointment/book-event",
      method: "POST",
      data: {
        visitor_name: values.name,
        visitor_id: id,
        event_id: values?.event,
        visitor_card: values?.documentFile,
        state_id: values?.state,
        district_id: values?.district,
        email_id: values?.email,
        designation: values?.designation,
        organization_name: values?.organization,
        total_price:
          (values?.eventAmount?.amount +
            values?.subEventAmount?.reduce((totalValue, currentValue) => {
              return totalValue + currentValue.amount;
            }, 0)) *
          100,
        sub_events: values?.subEvent,
      },
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          sessionStorage.setItem("id", response.data?.id);
          sessionStorage.setItem("ref_id", response.data?.ref_id);
          const options = {
            key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
            amount: response.data?.amount,
            currency: "INR",
            name: "Verismart ai",
            description: "Test Transaction",
            image:
              "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1715171763251_verismart.023471b27268259232de.png",
            order_id: response.data?.order_id,
            handler: async function (respon) {
              const data = {
                payment_id: respon?.razorpay_payment_id,
                order_id: respon?.razorpay_order_id,
                signature: respon?.razorpay_signature,
              };

              axiosApi({
                url: "/booking/appointment/payment/status",
                method: "POST",
                data: { ...data },
                disableRedirect: true,
              })
                .then((resp) => {
                  if (resp.status) {
                    navigate(
                      `${pages.Verification?.route}?visitor_id=${response.data?.visitor_id}&booking_type=event&ref_id=${response.data?.ref_id}&id=${response.data?.id}`
                    );
                  } else {
                    dispatch(
                      alert({
                        type: "error",
                        message: response.message?.displayMessage,
                      })
                    );
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            },
            prefill: {
              name: values?.name,
              email: response?.data.email_id,
              contact: response?.data.phone_number,
            },
            notes: {
              address: "Soumya Dey Corporate Office",
            },
            theme: {
              color: "#61dafb",
            },
          };
          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: mobile,
      email: sessionStorage.getItem("email") || "",
      event: "",
      eventAmount: "",
      subEvent: [],
      subEventAmount: [],
      state: "",
      district: "",
      organization: "",
      designation: "",
      documentFile: "",
    },
    validationSchema,
    onSubmit,
  });

  var validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email format"
      )
      .required("Email is required"),
    name: Yup.string().required("Name is requied"),
    event: Yup.string().required("Event is required"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
    organization: Yup.string(),
    designation: Yup.string(),
    documentFile: Yup.mixed(),
  });

  useEffect(() => {
    loader.start(2);
    axiosApi({
      url: "/admin/appointments/states",
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          if (response.data.length > 0) {
            const formattedState = response.data.map((state) => ({
              id: state?.state_id,
              name: state?.state_name,
            }));

            setStateOptions(formattedState);
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message?.displayMessage,
              })
            );
          }
        }
        loader.apiComplete();
      })
      .catch((error) => {
        console.error("Error in fetching states list", error);
      });

    const event_id = sessionStorage.getItem("event_id");

    let URL;

    if (event_id) {
      URL = `admin/events?event_id=${event_id}`;
    } else {
      URL = "/admin/events/?only_enabled=true&registration_open=true";
    }

    axiosApi({
      url: URL,
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          if (
            response.data?.length > 0 ||
            Object.keys(response.data)?.length > 0
          ) {
            if (event_id) {
              setEventOptions([response.data]);

              formik.setFieldValue("event", response.data?.id);

              setEventObject(response.data);
            } else {
              setEventOptions(response.data);
            }
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message?.displayMessage,
              })
            );
          }
          loader.apiComplete();
        }
      })
      .catch((error) => {
        console.error("Error in fetching events list", error);
      });
  }, [loader]);

  useEffect(() => {
    if (eventObject?.id) {
      axiosApi({
        url:
          "/admin/events/?only_enabled=true&registration_open=true&parent_id=" +
          eventObject?.id,
        method: "GET",
      })
        .then((response) => {
          if (response.status) {
            setSubEventOptions(response.data);
          }
        })
        .catch((error) => {
          console.error("Error in fetching events list", error);
        });
    }
  }, [eventObject]);

  const handleInputChange = (e) => {
    const regex = /^[A-Za-z\s]*$/;

    const inputValue = e.target.value;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  const handleDownloadPDF = (link) => {
    const pdfLink = link;

    window.open(pdfLink, "_blank");
  };

  const handleEventSelect = (event) => {
    formik.setFieldValue("eventAmount", {
      event: "",
      amount: "",
    });

    formik.setValues((prev) => ({
      ...prev,
      subEvent: [],
      subEventAmount: [],
    }));

    const selectedEventId = event.target.value;

    const selectedEvent = eventOptions.find(
      (event) => event.id === parseInt(selectedEventId)
    );

    formik.setFieldValue("event", selectedEvent.id);

    formik.setFieldValue("eventAmount", {
      event: selectedEvent?.name,
      amount: selectedEvent?.ticket_cost,
    });

    setEventObject(selectedEvent);
  };

  const handleSubEvent = (event, formik) => {
    const selectedEventId = event.target.value;

    const selectedSubEvent = subEventOptions.filter((event) => {
      return (
        event.id ===
        selectedEventId.filter((item) => {
          return event.id === parseInt(item);
        })[0]
      );
    });

    formik.setValues((prev) => ({
      ...prev,
      subEvent: [...selectedEventId],
      subEventAmount: [
        ...selectedSubEvent.map((items) => {
          return {
            event: items?.name,
            amount: items?.ticket_cost,
          };
        }),
      ],
    }));
  };

  const handleStateChange = (event) => {
    setDistrictOptions([]);

    const selectedState = event.target.value;

    formik.setFieldValue("state", selectedState);

    formik.setFieldValue("district", "");

    fetchDistricts(selectedState);
  };

  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axiosApi({
        url: `/admin/appointments/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        const formattedDistricts = response.data.map((district) => ({
          id: district?.district_id,
          name: district?.district_name,
        }));

        setDistrictOptions(formattedDistricts);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  return (
    <Loader>
      <OuterBox>
        <SubheaderSection
          textAlign="left"
          title="Book Event"
          content="Please provide the following details to Join the Event"
        />

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="event"
                label="Select Main Event"
                options={stateOptions}
                onChange={handleEventSelect}
                formikProps={formik}
                required
              >
                {eventOptions.map((items, index) => {
                  return (
                    <MenuItem
                      sx={{ justifyContent: "space-between" }}
                      value={items?.id}
                    >
                      <span>{items?.name} </span>
                      <span>Rs.{items?.ticket_cost}</span>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            {formik.values.eventAmount && (
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="subEvent"
                  label="Select Sub Event"
                  onChange={(e) => handleSubEvent(e, formik)}
                  options={stateOptions}
                  formikProps={formik}
                  SelectProps={{
                    multiple: true,
                  }}
                >
                  {subEventOptions.length === 0 && (
                    <MenuItem disabled>No Sub Event Found</MenuItem>
                  )}
                  {subEventOptions?.map((items, index) => {
                    return (
                      <MenuItem
                        sx={{
                          justifyContent: "space-between",
                          background: "antiquewhite",
                          fontWeight: "500",
                        }}
                        value={items?.id}
                      >
                        <span>{items?.name} </span>
                        <span>Rs.{items?.ticket_cost}</span>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}

            {formik.values.eventAmount.amount && (
              <Grid item container>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <td style={{ fontSize: "large", fontWeight: "600" }}>
                        Event Name
                      </td>
                      <td style={{ fontSize: "large", fontWeight: "600" }}>
                        Event Rate
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formik.values.eventAmount?.event}</td>
                      <td>Rs {formik.values.eventAmount?.amount}</td>
                    </tr>
                    {formik.values.subEventAmount.map((items, index) => {
                      return (
                        <tr>
                          <td>{items?.event}</td>
                          <td>Rs {items?.amount}</td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td style={{ fontSize: "large", fontWeight: "500" }}>
                        Total
                      </td>
                      <td style={{ fontSize: "large", fontWeight: "500" }}>
                        Rs{" "}
                        {formik.values.eventAmount?.amount +
                          formik.values?.subEventAmount.reduce(
                            (totalValue, currentValue) => {
                              return totalValue + currentValue.amount;
                            },
                            0
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            )}

            {eventObject.name && (
              <Grid item xs={12} sm={12}>
                <EventCard
                  eventObject={eventObject}
                  handleDownloadPDF={handleDownloadPDF}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <TextField name="name" label={"Name"} formikProps={formik} />
            </Grid>
            <Grid mt={1} item xs={12} sm={6}>
              <TextField
                name="mobile"
                label={"Mobile No"}
                formikProps={formik}
                disabled
              />
            </Grid>

            <CommonTextField
              label="Enter Your Email"
              placeholder="Enter Your Email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              required
            />

            <SelectField
              label="Choose Your State"
              value={formik.values.state}
              onChange={handleStateChange}
              options={stateOptions}
              error={formik.touched.state && Boolean(formik.errors.state)}
              required
            />

            <SelectField
              label="Choose Your District"
              name="district"
              value={formik.values.district}
              options={districtOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.district && formik.errors.district}
              required
            />

            <CommonTextField
              label="Enter Your organization's Name(Optional)"
              placeholder="Enter Your organization's Name(Optional)"
              name="organization"
              type="text"
              value={formik.values.organization}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
            />
            <CommonTextField
              label="Enter Your Designation(Optional)"
              placeholder="Enter Your Designation Name(Optional)"
              name="designation"
              type="text"
              value={formik.values.designation}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
            />

            <DocumentUpload
              setUploadLoading={setUploadLoading}
              uploadLoading={uploadLoading}
              formik={formik}
            />
          </Grid>

          <LoadingButton
            loading={loading}
            onClick={formik.handleSubmit}
            disabled={uploadLoading || !formik.dirty || !formik.isValid}
            fullWidth
          >
            Proceed to Pay
            {formik.values.eventAmount?.amount &&
              `: INR ${
                formik.values.eventAmount?.amount +
                formik.values?.subEventAmount?.reduce(
                  (totalValue, currentValue) => {
                    return totalValue + currentValue.amount;
                  },
                  0
                )
              }`}
          </LoadingButton>

          <MandatoryNote />
        </form>
      </OuterBox>
    </Loader>
  );
};

export default MyComponent;
