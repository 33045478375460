import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Autorenew as ResendIcon } from "@mui/icons-material";

const ResendOTPButton = ({ onClick, disabled }) => {
  const [countdown, setCountdown] = useState(0);
  const [disabledResendOTP, setDisabledResendOTP] = useState(false);

  const handleResendOTP = () => {
    setDisabledResendOTP(true);
    setCountdown(60);

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setDisabledResendOTP(false);
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Typography variant="body2" sx={{ paddingRight: "6px" }}>
        Didn't get OTP?
      </Typography>
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => {
          onClick();
          handleResendOTP();
        }}
        startIcon={<ResendIcon />}
        disabled={disabledResendOTP || disabled}
      >
        {disabledResendOTP ? `Resend OTP (${countdown}s)` : "Resend OTP"}
      </Button>
    </Grid>
  );
};

export default ResendOTPButton;
