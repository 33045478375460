import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, useTheme } from "@mui/material";
import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
} from "./../../../components";
import EventCard from "./EventCard";
import pages from "../../../constants/pages";
import { useNavigate, useLocation } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const MyComponent = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { details } = location.state || {};
  const mapLink = details.map_link;
  const [userPayload, setUserPayload] = useState(
    JSON.parse(localStorage.getItem("userPayload")) || ""
  );
  const isVerificationExist = sessionStorage.getItem("is_verified");
  const [btnText, setBtnText] = useState("Start Verification Process");

  useEffect(() => {
    if (
      isVerificationExist &&
      userPayload &&
      sessionStorage.getItem("visitorId") === userPayload.visitor_id
    ) {
      setBtnText("Initiate Liveness Check");
    } else {
      setBtnText("Start Verification Process");
    }
  }, [isVerificationExist, userPayload]);

  const handleDownloadPDF = () => {
    const pdfLink = details.brochure;
    window.open(pdfLink, "_blank");
  };

  const handleProceed = () => {
    if (
      isVerificationExist &&
      userPayload &&
      sessionStorage.getItem("visitorId") === userPayload.visitor_id
    ) {
      const updatedUserPayload = {
        ...userPayload,
        booking_type: "event",
      };
      setUserPayload(updatedUserPayload);
      localStorage.setItem("userPayload", JSON.stringify(updatedUserPayload));
      navigate(pages.checkLiveliness.route, {
        state: {
          visitor_id: details.visitor_id,
        },
      });
    } else {
      const destination = `${pages.Verification.route}?visitor_id=${details.visitor_id}&id=${details.booking_id}&booking_type=event`;
      navigate(destination);
    }
  };

  const theme = useTheme();

  return (
    <OuterBox>
      <SubheaderSection textAlign="left" title="Event Details" />

      <EventCard eventObject={details} handleDownloadPDF={handleDownloadPDF} />

      <Box sx={{ padding: theme.spacing(3) }}>
        <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Your email:
            </Typography>
            <Typography variant="body1">{details.email_id}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event name:
            </Typography>
            <Typography variant="body1">{details.event_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event Date:
            </Typography>
            <Typography variant="body1">{details.date}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event Start time:
            </Typography>
            <Typography variant="body1">{details.start_time}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Event End time:
            </Typography>
            <Typography variant="body1">{details.end_time}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Venue:
            </Typography>
            <Typography variant="body1">{details.full_address}</Typography>
          </Grid>

          {mapLink && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight="bold">
                Event Map:
              </Typography>
              <a
                href={mapLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <LocationOnIcon style={{ marginRight: "4px" }} />
                Click Here
              </a>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* {details.brochure && (
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            sx={{
              width: "177px",
              height: "35px",
              background: "#FFFFFF",
              border: "1px solid #E5252A",
              borderRadius: "5px",
              color: "#E5252A",
            }}
            onClick={handleDownloadPDF}
          >
            DOWNLOAD PDF
          </Button>
        </Box>
      )} */}

      <LoadingButton onClick={handleProceed}>{btnText}</LoadingButton>
    </OuterBox>
  );
};

export default MyComponent;
