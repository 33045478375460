import React, { useState } from "react";
import { FormControl, Grid, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LoadingButton } from "./../../components";
import AwsUpload from "./../../utils/AwsUpload";
import { useDispatch } from "react-redux";
import { useAlert } from "./../../hooks/";
const DocumentUpload = ({ formik, uploadLoading, setUploadLoading }) => {
  const [documentFileName, setDocumentFileName] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(false);
  const inputFileRef = React.createRef();
  const dispatch = useDispatch();
  const handleSelectFileClick = () => {
    inputFileRef.current.click();
  };
  const { alert } = useAlert();
  const handleFileChange = async (event) => {
    setUploadStatus(false);
    try {
      const file = event.target.files[0];

      if (!file) {
        return;
      }
      event.target.value = null;
      if (file.size > 2 * 1024 * 1024) {
        dispatch(
          alert({
            type: "error",
            message: "File size exceeds 2MB. Please choose a smaller file.",
          })
        );
        return;
      }

      setUploadLoading(true);

      const Location = await AwsUpload(file);
      setUploadLoading(false);
      if (Location) {
        const fileName =
          file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name;
        setDocumentFileName(fileName);

        formik.setFieldValue("documentFile", Location); // Use setFieldValue directly
        setUploadStatus(true);
        // dispatch(
        //   alert({
        //     type: "success",
        //     message: "File Upload Successful",
        //   })
        // );
      } else {
        dispatch(
          alert({
            type: "error",
            message: "Error in uploading file ",
          })
        );
      }
    } catch (error) {
      console.error("Error uploading file to S3", error);
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ marginBottom: "16px" }}
        error={
          formik.touched.documentFile && Boolean(formik.errors.documentFile)
        }
      >
        <Typography variant="body1" sx={{ marginBottom: "4px" }}>
          Upload Your Visiting Card (Optional)
        </Typography>

        <Typography sx={{ color: "gray", fontSize: "12px" }}>
          File size: Up to 2 MB
          <br />
          Formats: PDF, JPG, PNG
        </Typography>

        <LoadingButton
          loading={uploadLoading}
          backgroundColor="gray"
          component="span"
          variant="contained"
          startIcon={<AttachFileIcon />}
          float="left"
          onClick={handleSelectFileClick}
        >
          {documentFileName ? documentFileName : "Select File"}
        </LoadingButton>

        <input
          type="file"
          id="documentFile"
          name="documentFile"
          ref={inputFileRef}
          onChange={handleFileChange}
          accept=".pdf,.jpeg,.png"
          style={{ display: "none" }}
        />

        {formik.touched.documentFile && formik.errors.documentFile && (
          <Typography variant="body2" color="error">
            {formik.errors.documentFile}
          </Typography>
        )}

        {uploadStatus && (
          <Typography sx={{ color: "green", fontSize: "12px" }}>
            File Upload Successful
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

export default DocumentUpload;
