import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import {
  OuterBox,
  SubheaderSection,
  SubmitButton,
} from "./../../../components";
import queryString from "query-string";
import pages from "../../../constants/pages";
import { useNavigate, useLocation } from "react-router-dom";

const MyComponent = ({ setBookAppointment }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const { visitor_id } = location.state || {};

  const handleGoBack = () => {
    navigate(-1);
  };

  const userPayloadString = localStorage.getItem("userPayload");
  const userPayload = JSON.parse(userPayloadString);

  const handleProceed = () => {
    navigate(pages.checkLiveliness.route, {
      state: {
        visitor_id: visitor_id,
      },
    });
  };

  return (
    <OuterBox>
      <SubheaderSection textAlign="left" title="Aadhar Details" />

      <form>
        <Box sx={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" align="left" fontWeight="bold">
                Aadhaar Number:
              </Typography>
              <Typography variant="body1">{userPayload.aadharNo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" align="left" fontWeight="bold">
                First Name:
              </Typography>
              <Typography variant="body1">{userPayload.first_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" align="left" fontWeight="bold">
                Last Name:
              </Typography>
              <Typography variant="body1">{userPayload.last_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" align="left" fontWeight="bold">
                Date of Birth:
              </Typography>
              <Typography variant="body1">{userPayload.dob}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlign: "center", mt: 3 }}>
          <SubmitButton
            onClick={handleProceed}
            text="Initiate Liveness Check"
          />
        </Box>
      </form>
    </OuterBox>
  );
};

export default MyComponent;
