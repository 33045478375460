import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { LoadingButton, ButtonContainer } from "./../../components";
import { useAlert } from "../../hooks";

const MobileNumberInput = ({
  loading,
  onSubmit,
  setMobileNumber,
  mobileNumber,
  handleUserConsentChange,
  handleGoBack,
}) => {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const handleMobileNumberChange = (event) => {
    const inputNumber = event.target.value;

    // Remove any non-digit characters from the input
    const cleanedInput = inputNumber.replace(/\D/g, "");

    // Check if the cleaned input starts with 6, 7, 8, or 9
    if (/^[6-9]/.test(cleanedInput) || cleanedInput === "") {
      setMobileNumber(cleanedInput);
    }
  };

  const handleGetOTP = () => {
    if (mobileNumber.length !== 10 || !/^[0-9]+$/.test(mobileNumber)) {
      // console.log(mobileNumber);
      dispatch(
        alert({
          type: "error",
          message: "Please enter a valid 10-digit mobile number.",
        })
      );
      return;
    }

    onSubmit(mobileNumber);
  };

  return (
    <form>
      <TextField
        label="Mobile Number"
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter Your 10 Digit Mobile Number"
        inputProps={{
          type: "tel",
          maxLength: 10,
          pattern: "[6-9]\\d*", // Pattern to match numbers starting with 6, 7, 8, or 9
        }}
        value={mobileNumber}
        onChange={handleMobileNumberChange}
      />

      <FormControlLabel
        control={<Checkbox />}
        label="Are you available on Whatsapp?"
        onClick={handleUserConsentChange}
      />
      <ButtonContainer>
        <LoadingButton loading={loading} onClick={handleGetOTP}>
          Get OTP
        </LoadingButton>

        <LoadingButton onClick={handleGoBack} backgroundColor="gray">
          Back
        </LoadingButton>
      </ButtonContainer>
    </form>
  );
};

export default MobileNumberInput;
