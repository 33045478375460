import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Grid, Button } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { OuterBox } from "../../../components";

const AadharverificaionSuccess = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const qrcodeurl = queryParams.get("qrcodeurl");

  return (
    <OuterBox>
      {" "}
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          border: "#ffffff",
          height: "30vh",
          // paddingTop: "45px",
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          spacing={3}
        >
          <CheckCircleOutlineRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
              marginBottom: "30px",
            }}
          />
          <Typography
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
            }}
          >
            Verification has been completed successfully.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              marginTop: "15px",
              "&:hover": { background: "rgb(41 128 31)", color: "white" },
            }}
            href={qrcodeurl}
          >
            Download QR Code
          </Button>
        </Grid>
      </Container>
    </OuterBox>
  );
};

export default AadharverificaionSuccess;
