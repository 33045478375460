import { Box } from "@mui/system";
import styled from "styled-components/macro";
import { Fragment, useEffect, useState } from "react";
import { Paper as MuiPaper, Grid, Typography, Tooltip } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardAltRoundedIcon from "@mui/icons-material/KeyboardAltRounded";
import DoorSlidingRoundedIcon from "@mui/icons-material/DoorSlidingRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";

import pages from "../../constants/pages";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Paper = styled(MuiPaper)`
  background-color: rgb(226 226 226);
  border-radius: 0px;
  text-align: center;
  color: #fff;
  display: flex;
`;

const TabButton = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  font-size: 15px;
  font-family: "Open Sans, sans-serif";
  font-weight: 400;
  align-items: center;
`;

export default function Footer() {
  const navigate = useNavigate();

  const location = useLocation();

  const [selectOption, setSelectOption] = useState(location.pathname);

  const match = [
    "/manual-entry",
    "/resend-notification",
    "/manual-verification",
    "/main-gate-entry-back",
    "/main-gate-entry-front",
  ].filter((item) => location.pathname == item);

  function stopCamera() {
    const stream = document.querySelector("video")?.srcObject;
    if (stream) {
      const tracks = stream?.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      document.querySelector("video").srcObject = null;
    }
  }

  const handleNavigation = (route) => {
    if (selectOption !== route) {
      stopCamera();
      setSelectOption(route);
      navigate(route);
    }
  };

  useEffect(() => {
    setSelectOption(location.pathname);
  }, [location]);

  return (
    <Fragment>
      {Boolean(match.length) && (
        <Paper>
          <Grid
            item
            container
            justifyContent={"center"}
            xs={4}
            onClick={() => {
              handleNavigation(pages.MainGateEntryWithBackCamera.route);
            }}
            sx={{
              background:
                selectOption === "/main-gate-entry-back" ||
                selectOption === "/main-gate-entry-front"
                  ? "#2d9783"
                  : "rgb(226 226 226)",
            }}
          >
            <Tooltip title="Auto Entry">
              <TabButton>
                <DoorSlidingRoundedIcon
                  sx={{
                    fontSize: "35px",
                    color:
                      Boolean(
                        selectOption !== "/main-gate-entry-back" &&
                          selectOption !== "/main-gate-entry-front"
                      ) && "#2d9783",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color:
                      Boolean(
                        selectOption !== "/main-gate-entry-back" &&
                          selectOption !== "/main-gate-entry-front"
                      ) && "#2d9783",
                  }}
                >
                  Auto Entry
                </Typography>
              </TabButton>
            </Tooltip>
          </Grid>

          <Grid
            item
            container
            justifyContent={"center"}
            xs={4}
            sx={{
              background:
                selectOption === "/manual-entry"
                  ? "#2d9783"
                  : "rgb(226 226 226)",
            }}
            onClick={() => {
              handleNavigation(pages.ManualEntry.route);
            }}
          >
            <Tooltip title="Manual Entry">
              {" "}
              <TabButton>
                <KeyboardAltRoundedIcon
                  sx={{
                    fontSize: "35px",
                    color: selectOption !== "/manual-entry" && "#2d9783",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: selectOption !== "/manual-entry" && "#2d9783" }}
                >
                  Manual Entry
                </Typography>
              </TabButton>
            </Tooltip>
          </Grid>

          <Grid
            item
            container
            justifyContent={"center"}
            xs={4}
            sx={{
              background:
                selectOption === "/resend-notification"
                  ? "#2d9783"
                  : "rgb(226 226 226)",
            }}
            onClick={() => {
              handleNavigation(pages.ResendNotification.route);
            }}
          >
            {" "}
            <Tooltip title="Resend Notification">
              {" "}
              <TabButton>
                <NotificationsRoundedIcon
                  sx={{
                    fontSize: "35px",
                    color: selectOption !== "/resend-notification" && "#2d9783",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: selectOption !== "/resend-notification" && "#2d9783",
                  }}
                >
                  Resend Notification
                </Typography>
              </TabButton>
            </Tooltip>
          </Grid>

          <Grid
            item
            container
            justifyContent={"center"}
            xs={4}
            sx={{
              background:
                selectOption === "/manual-verification"
                  ? "#2d9783"
                  : "rgb(226 226 226)",
            }}
            onClick={() => {
              handleNavigation(pages.ManualVerification.route);
            }}
          >
            {" "}
            <Tooltip title="Manual Verification">
              {" "}
              <TabButton>
                <VerifiedIcon
                  sx={{
                    fontSize: "35px",
                    color: selectOption !== "/manual-verification" && "#2d9783",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: selectOption !== "/manual-verification" && "#2d9783",
                  }}
                >
                  Manual Verification
                </Typography>
              </TabButton>
            </Tooltip>
          </Grid>
        </Paper>
      )}
    </Fragment>
  );
}
