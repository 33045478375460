import React, { Fragment } from "react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "./assets/img/getyourpass2.png"; // Import the images
import CarouselComponent from "./Carousel";
import { motion } from "framer-motion";
const useStyles = makeStyles((theme) => ({
  getYourPassWrap: {
    padding: "88px 0",
    position: "relative",
    background: `url(${Image}) top center no-repeat`,
    backgroundSize: "cover",
  },

  heading: {
    margin: "0",
    textAlign: "center",
    paddingBottom: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 600,
    fontFamily: '"Montserrat", sans-serif',
  },
  info: {
    fontSize: "20px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start", // Left-align on smaller screens
    },
  },
}));

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
};

function ImageDisplay() {
  const classes = useStyles();
  return (
    <Fragment>
      <CarouselComponent />
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid className={classes.getYourPassWrap}>
          <Container>
            <Typography variant="h4" gutterBottom className={classes.heading}>
              Contact Information
            </Typography>
            <div className={classes.centerText}>
              <Typography variant="h5" className={classes.info}>
                Corporate Office (Bhopal)
              </Typography>

              <Typography variant="h6" className={classes.info}>
                MPSeDC Ltd. State IT Centre, 47-A, Arera Hills, Bhopal-462011
                (M.P.)
              </Typography>
              <Typography variant="h6" className={classes.info}>
                Telephone: +91 755 2518300, +91 755 2518500
              </Typography>
              <Typography variant="h6" className={classes.info}>
                Fax: +91 755 2579824
              </Typography>
            </div>
          </Container>
        </Grid>
      </motion.div>
    </Fragment>
  );
}

export default ImageDisplay;
