import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Link,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import LocationIcon from "@mui/icons-material/LocationOn";
import Dummy from "./../assests/dummy.png";

const EventCard = ({ eventObject, handleDownloadPDF }) => {
  return (
    <Card
      sx={{
        border: "1px solid #ccc",
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
        borderRadius: "30px",
        marginBottom: "20px",
      }}
    >
      <CardContent
        sx={{
          py: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            // marginBottom: "4px",
            textTransform: "capitalize",
          }}
        >
          {eventObject.name ? eventObject.name : eventObject.event_name}
        </Typography>
        {/* Rest of your content */}
      </CardContent>

      <CardMedia
        component="img"
        sx={{ padding: "15px", height: "200px", width: "100%" }}
        image={eventObject.banner_image ? eventObject.banner_image : Dummy}
        alt={eventObject.name}
      />

      <CardContent sx={{ padding: "15px" }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flex: 1 }}>
            <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
              <Grid item xs={12} sm={6}>
                {eventObject.map_link ? (
                  <Tooltip title="View on Map">
                    <Link
                      href={`${eventObject.map_link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      underline="none"
                    >
                      <Box display="flex" alignItems="center">
                        <LocationIcon
                          sx={{ marginRight: "4px", color: "blue" }}
                        />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {eventObject.full_address}
                        </Typography>
                      </Box>
                    </Link>
                  </Tooltip>
                ) : (
                  <Box display="flex" alignItems="center">
                    <LocationIcon sx={{ marginRight: "4px" }} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {eventObject.full_address}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          {eventObject.brochure && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => handleDownloadPDF(eventObject.brochure)}
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginTop: { xs: "8px", sm: 0 },
                backgroundColor: "#107DE2",
                color: "#FFF",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#107DE2",
                  borderRadius: "0px",
                },
              }}
            >
              Download Brochure
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventCard;
