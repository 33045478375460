import React from "react";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  dots: {
    display: "flex",
  },
  dot: {
    width: "3rem",
    height: "3rem",
    color: "#0463FA",
    backgroundColor: "#0463FA",
    borderRadius: "50%",
    margin: theme.spacing(0.5),
    animation: "$blink 1s infinite",
  },
  "@keyframes blink": {
    "0%": {
      transform: "scale(0)",
      opacity: 0,
    },
    "50%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(0)",
      opacity: 0,
    },
  },
}));

const DotLoading = () => {
  const classes = useStyles();

  return (
    <Container className={classes.loadingContainer}>
      <div className={classes.dots}>
        <div className={classes.dot}></div>
      </div>
    </Container>
  );
};

export default DotLoading;
